/* eslint-disable camelcase */
import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosInstance, endPoints, queryClient } from 'api';
import { useRef, useEffect } from 'react';
import { useZustandStore } from 'zustandStore';

import { getCulturals } from './culturals';
import { useLinkedCulturalStore } from 'zustandStore/linkedCulturalsStore';

const setClass = (category, event, shouldClear) => setClassification => {
    const option = {
        [`${category.category_level}`]: {
            code: category.category_level >= 3 ? category.id : category.custom_id,
            custom_id: category.custom_id,
            id: category.id
        }
    };

    setClassification(`class_${event?.class_level}`, option, shouldClear);
};

const setClassesFromCategory = (categories, event, shouldClear) => setClassification => {
    const orderedCategoriesByLevel = [...categories.sort((curr, next) => curr.category_level - next.category_level)];

    orderedCategoriesByLevel.forEach(c => setClass(c, event, shouldClear)(setClassification));
};

async function getEvents(categories) {
    const result = await axiosInstance.get(endPoints.events.list(categories));

    return result?.data?.data;
}

export const useEvents = (categories) => {
    const { data, isFetching } = useQuery({
        queryFn: () => getEvents(categories),
        queryKey: [endPoints.events.list(categories)],
        enabled: !!categories
    });

    return {
        data: data ? data?.map(evt => ({ ...evt, checked: false })) : [],
        isLoading: isFetching
    };
};

async function getEvent(id) {
    const response = await axiosInstance.get(endPoints.events.event(id));

    return response.data;
}

export const useEventCulturals = (id, categories, enabled) => {
    const _prevEvent = useRef(null);
    const { setClassification, setActiveTab } = useZustandStore();
    const { putCulturalContent } = useLinkedCulturalStore();

    const {
        data: event,
        isFetching: isFetchingEvent,
        isLoading
    } = useQuery({
        queryKey: [endPoints.events.event(id)],
        queryFn: () => getEvent(id),
        enabled,
        onSuccess: async (res) => {
            setActiveTab(`class_${res?.class_level}`);

            setClassesFromCategory(res?.categories, res, true)(setClassification);

            const otherClasses = res.classes.filter(e => e.id !== res.id);

            const cltrls = await getCulturals(res.categories.map(evt => evt.id));

            let prevCulturalContent = [...cltrls];

            res.cultural_contents.forEach((cur) => {
                if (prevCulturalContent.find(cltrl => cltrl.id === cur.id)) {
                    prevCulturalContent = prevCulturalContent.filter(cltrl => cltrl.id !== cur.id);
                }

                prevCulturalContent.push({ ...cur, checked: true });
            });

            putCulturalContent(prevCulturalContent);

            otherClasses.forEach(e => {
                setClassesFromCategory(e?.categories, e)(setClassification);
            });

            _prevEvent.current = id;
        }
    });

    const { isFetching: isFetchingCategoryCulturals } = useQuery({
        queryFn: () => getCulturals(categories),
        queryKey: [endPoints.culturals.list(categories)],
        enabled: !!categories,
        onSuccess: res => {
            putCulturalContent(res?.map(cltrl => ({ ...cltrl, checked: false })));
        }
    }
    );

    useEffect(() => {
        if (_prevEvent.current !== id) {
            // saveCulturals(prev => [...prev.filter(cltrl => !cltrl.checked)]);
        }
    }, [id]);

    return {
        event,
        isLoadingEvent: isFetchingEvent && isLoading,
        isFetchingCategoryCulturals
    };
};

async function createEvent(data) {
    const response = await axiosInstance.post(endPoints.events.create, data);

    if (!response.status || response.status !== 201) {
        const error = new Error();

        error.message = response.response?.data?.message ?? 'Could not create the event!';

        throw error;
    }

    return response.data;
}

export const useCreateEvent = () => {
    const { createAlert } = useZustandStore();
    const { isLoading: isCreatingEvent, data: createdEvent, mutateAsync: onCreateEvent } = useMutation(createEvent, {
        onError: ({ message }) => {
            createAlert({
                type: 'alert-error',
                message
            });
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [endPoints.events.main]
            });
            await queryClient.invalidateQueries({
                queryKey: [endPoints.events.highlights]
            });
        }
    });

    return {
        onCreateEvent,
        isCreatingEvent,
        createdEvent
    };
};

async function deleteEvent(event) {
    const response = await axiosInstance.get(endPoints.events.event(event?.id));

    await axiosInstance.delete(endPoints.events.delete(event?.id));

    return response.data;
}

export const useDeleteEvent = () => {
    const { createAlert, resetEvent } = useZustandStore();
    const { mutate: onDeleteEvent, isLoading: isDeletingEvent } =
        useMutation({
            mutationFn: deleteEvent,
            onSuccess: async (res) => {
                const categories = [...res.categories, ...res.classes.map(c => c.categories).flat()];

                await Promise.all(categories.map(async categ => {
                    await queryClient.invalidateQueries({
                        queryKey: [endPoints.events.list(categ.id)]
                    });
                }));

                await queryClient.invalidateQueries({
                    queryKey: [endPoints.events.main]
                });
                await queryClient.invalidateQueries({
                    queryKey: [endPoints.events.highlights]
                });

                resetEvent();
                createAlert({
                    type: 'alert-success',
                    message: `${res?.title} has been deleted!`
                });
            }
        });

    return {
        onDeleteEvent,
        isDeletingEvent
    };
};

async function updateEvent({ id, data }) {
    const response = await axiosInstance.put(
        endPoints.events.update(id),
        data
    );

    if (!response.status || response.status !== 200) {
        const error = new Error();

        error.message = response.message ?? 'Could not update the event!';

        throw error;
    }

    return response.data;
}

export const useUpdateEvent = () => {
    const { createAlert } = useZustandStore();
    const { mutateAsync: onUpdateEvent, isLoading: isUpdatingEvent } =
        useMutation({
            mutationFn: updateEvent,
            onError: ({ message }) => {
                createAlert({
                    type: 'alert-error',
                    message
                });
            },
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: [endPoints.events.main]
                });
                await queryClient.invalidateQueries({
                    queryKey: [endPoints.events.highlights]
                });
            }
        });

    return {
        onUpdateEvent,
        isUpdatingEvent
    };
};

async function getMainEvents() {
    const result = await axiosInstance.get(endPoints.events.main);

    return result?.data;
}

export const useMainEvents = (enabled) => {
    const query = useQuery({
        queryFn: getMainEvents,
        queryKey: [endPoints.events.main],
        enabled
    });

    return query;
};

async function removeFromMainEvents({ event }) {
    const result = await axiosInstance.delete(endPoints.events.removeFromMain(event));

    return result?.data;
}

export const useRemoveFromMainEvents = () => {
    const mutation = useMutation(removeFromMainEvents, {
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [endPoints.events.main]
            });
        }
    });

    return mutation;
};

async function getHighlightEvents() {
    const result = await axiosInstance.get(endPoints.events.highlights);

    return result?.data;
}

export const useHighlightEvents = (enabled) => {
    const query = useQuery({
        queryFn: getHighlightEvents,
        queryKey: [endPoints.events.highlights],
        enabled
    });

    return query;
};

async function removeFromHighlightEvents({ event }) {
    const result = await axiosInstance.delete(endPoints.events.removeFromHighlights(event));

    return result?.data;
}

export const useRemoveFromHighlightEvents = () => {
    const mutation = useMutation(removeFromHighlightEvents, {
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [endPoints.events.highlights]
            });
        }
    });

    return mutation;
};
