import { useRef } from 'react';

export const useDataForm = () => {
    const dataForm = useRef({});

    const clearData = () => {
        dataForm.current = {};
    };

    const createData = (key, value) => {
        Object.assign(dataForm.current, { [key]: value });
    };

    return {
        dataForm: dataForm.current,
        createData,
        clearData
    };
};
