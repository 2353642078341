import React from 'react';
import PropTypes from 'prop-types';

function Selector(props) {
    const onChange = ({ target }) => {
        const option = !target.value
            ? props.data?.find((category) => !category.title)
            : props.data?.find((category) => category?.title === target?.value);

        const code =
            option?.category_level >= 3 ? option?.id : option?.custom_id;

        const selectedCategory = {
            [option?.category_level]: {
                id: option?.id,
                custom_id: option?.custom_id,
                code
            }
        };

        props.onChange(selectedCategory);
    };

    return (
        <select className='select' name={props.name} onChange={onChange}>
            <option disabled={true} selected={!props.selected} hidden={true}>
                {props.placeHolder}
            </option>
            {props.data?.map(props.renderOption)}
        </select>
    );
}

Selector.propTypes = {
    name: PropTypes.string,
    placeHolder: PropTypes.string,
    onChange: PropTypes.func,
    data: PropTypes.array,
    renderOption: PropTypes.func,
    isFetching: PropTypes.bool,
    selected: PropTypes.bool
};

export default Selector;
