import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    alerts: [],
    activeCategory: {},
    activePanelCategory: {},
    isCategoryManagerActive: false,
    activeCultural: null,
    activeEvent: null,
    culturalImages: {
        full: null,
        tile: null
    },
    eventMedia: {
        img: null,
        video: null
    },
    highlighEvent: {
        cover: null
    },
    lang: 'en',
    activeTab: 'class_1',
    classification: {}
};

export const useZustandStore = create((set) => ({
    ...initialState,
    createAlert: (alert) =>
        set(({ alerts }) => ({
            alerts: [{ id: uuidv4(), ...alert }, ...alerts]
        })),
    closeAlert: (id) =>
        set(({ alerts }) => ({
            alerts: alerts.filter((alert) => alert.id !== id)
        })),
    clearAlerts: () => set(() => ({ alerts: [] })),
    setActiveCategory: (category) =>
        set(({ activeCategory }) => ({
            activeCategory: categorySelection(category)(activeCategory)
        })),
    setActivePanelCategory: (category) =>
        set(({ activePanelCategory }) => ({
            activePanelCategory:
                categorySelection(category)(activePanelCategory),
            isCategoryManagerActive: false
        })),
    setActiveManagerCategory: () =>
        set(() => ({ isCategoryManagerActive: true })),
    setActiveCultural: (activeCultural) => set(() => ({ activeCultural })),
    setActiveEvent: (activeEvent) => set(() => ({ activeEvent })),
    setCulturalImages: (images) =>
        set(({ culturalImages }) => ({
            culturalImages: { ...culturalImages, ...images }
        })),
    clearCulturalImages: () =>
        set(() => ({ culturalImages: { full: null, tile: null } })),
    setEventMedia: (media) =>
        set(({ eventMedia }) => ({ eventMedia: { ...eventMedia, ...media } })),
    clearEventMedia: () =>
        set(() => ({ eventMedia: { img: null, video: null } })),
    setHighlightCover: (cover) => set(() => ({ highlighEvent: { cover } })),
    clearHighlightCover: () => set(() => ({ highlighEvent: { cover: null } })),
    setLanguage: lang => set(() => ({ lang })),
    setActiveTab: activeTab => set(() => ({ activeTab })),
    setClassification: (tab, category, shouldClear) => set(({ classification }) =>
        shouldClear ? ({
            classification: {
                [tab]: categorySelection(category)(classification[tab] ?? {})
            }
        })
            : ({
                classification: {
                    ...classification,
                    [tab]: categorySelection(category)(classification[tab] ?? {})
                }
            })
    ),
    clearClassification: () => set(() => ({ classification: {}, activeTab: 'class_1' })),
    resetCultural: () => set(() => ({
        activeCultural: null,
        culturalImages: {
            full: null,
            tile: null
        }
    })),
    resetEvent: () => set(() => ({
        activeEvent: null,
        eventMedia: {
            img: null,
            video: null
        },
        highlighEvent: {
            cover: null
        }
    }))
}));

export const categorySelection = (category) => (activeCategory) => {
    const key = Object.keys(category)[0];
    const mapActiveCategory = Object.keys(activeCategory).reduce(
        (acc, currKey) => {
            if (+key >= +currKey) {
                acc[currKey] = activeCategory[currKey];
            }

            return acc;
        },
        {}
    );

    return { ...mapActiveCategory, ...category };
};

const initialAuthState = {
    isAuthed: false
};

export const useZustandAuth = create((set) => ({
    ...initialAuthState,
    authorize: () => set(() => ({ isAuthed: true }))
}));
