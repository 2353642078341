/* eslint-disable react/prop-types */
import { useDeleteCustomMessage } from 'hooks/customMessages';
import React from 'react';
import { POSITIONS, useIllustrationStore } from 'zustandStore/illustrationStore';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicator from '@material-ui/icons/DragIndicator';
import { useZustandStore } from 'zustandStore';
import { useTextFormatter } from 'hooks/useTextFormatter';
import { Reorder, useDragControls } from 'framer-motion';

function Illustration({ illustartion }) {
    const { lang } = useZustandStore();
    const deleteQuery = useDeleteCustomMessage();

    const controls = useDragControls();

    const {
        selectCard,
        setBackgroundColor,
        setTextContent,
        setFontSize,
        setTextPosition,
        setLetterSpacing,
        setIllustartionUrl,
        setIllustrationPosition,
        setLinkScreen,
        setLinkPosition,
        setLinkTitle,
        turnOnUpdateMode
    } = useIllustrationStore();

    const { formatText } = useTextFormatter();

    const formattedText = formatText(illustartion.translations?.find(translation => translation.translatable_field === 'text_content' && translation.lang === lang)?.content);

    const handleDelete = () => {
        deleteQuery.mutate(illustartion.id);
    };

    const handleOnPress = () => {
        selectCard(illustartion.id);
        setBackgroundColor(illustartion.background_color);
        setTextContent({
            lang: 'en',
            value: illustartion.translations.find(translation => translation.translatable_field === 'text_content' && translation.lang === 'en')?.content
        });
        setTextContent({
            lang: 'fr',
            value: illustartion.translations.find(translation => translation.translatable_field === 'text_content' && translation.lang === 'fr')?.content
        });
        setLinkTitle({
            lang: 'en',
            value: illustartion.translations.find(translation => translation.translatable_field === 'link_title' && translation.lang === 'en')?.content
        });
        setLinkTitle({
            lang: 'fr',
            value: illustartion.translations.find(translation => translation.translatable_field === 'link_title' && translation.lang === 'fr')?.content
        });
        setFontSize(illustartion.text_font_size);
        setTextPosition(illustartion.text_position);
        setLetterSpacing(illustartion.text_spacing);
        setIllustartionUrl(illustartion.illustartion_url);
        setIllustrationPosition(illustartion.illustartion_position);
        setLinkScreen(illustartion.link_screen);
        setLinkPosition(illustartion.link_position ?? POSITIONS[0]);
        turnOnUpdateMode();
    };

    return (
        <Reorder.Item
            value={illustartion}
            dragListener={false}
            dragControls={controls}>
            <div className='relative w-fit h-fit ml-10'>
                <div
                    className='
                    absolute
                    z-40
                    cursor-grab
                    right-[100%]
                    h-full
                    flex
                    flex-col
                    justify-center
                    px-2
                    opacity-60
                    '
                    onPointerDown={(e) => controls.start(e)}>
                    <DragIndicator />
                </div>
                <button
                    onClick={handleDelete}
                    className={`btn btn-sm btn-error btn-circle absolute -right-4 -top-4 z-50 ${
                            deleteQuery.isLoading
                                    ? 'loading'
                                    : ''
                            }`}>
                    {!deleteQuery.isLoading ? (
                        <DeleteIcon fontSize='small' style={{
                            color: '#fff'
                        }}/>
                    ) : null}
                </button>
                <button
                    onClick={handleOnPress}
                    style={{
                        backgroundColor: illustartion.background_color,

                        flexDirection:
                            illustartion.text_position === POSITIONS[0] ? 'row' : 'row-reverse'
                    }}
                    className='
                    cursor-pointer
                    focus:ring-4 shadow-lg transform active:scale-90 transition-transform
                    flex
                    p-3
                    relative
                    w-[379px]
                    h-[127px]
                    rounded-[6px]
                    text-white'>

                    <div
                        style={{
                            paddingRight: illustartion.text_position === POSITIONS[0] ? 16 : 0,
                            paddingLeft: illustartion.text_position === POSITIONS[1] ? 16 : 0
                        }}
                        className='flex-1 flex flex-col h-full'>
                        <span
                            style={{
                                letterSpacing: illustartion.text_spacing,
                                flex: 1,
                                fontSize: illustartion.text_font_size
                            }}>
                            {formattedText}
                        </span>
                        {
                            illustartion.link_screen
                                ? <span
                                    style={{
                                        alignSelf:
                                    illustartion.link_position === POSITIONS[0]
                                        ? 'flex-start'
                                        : 'flex-end'
                                    }}
                                    className='
                        border-2
                        rounded-md
                        p-1
                        hover:cursor-pointer'>
                                    {illustartion.translations?.find(translation => translation.translatable_field === 'link_title' && translation.lang === lang)?.content}
                                </span>
                                : null
                        }
                    </div>
                    <img
                        className='h-[136px] w-[136px] -translate-y-[26px] object-contain pointer-events-none'
                        src={illustartion.illustartion_url}
                        alt=''/>
                </button>
            </div>
        </Reorder.Item>
    );
}

export default Illustration;
