/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CATEGORIES_ICONS } from 'config/constants';
import { useOtherCategories } from 'hooks/categories';
import { useZustandStore } from 'zustandStore';
import DeleteIcon from '@material-ui/icons/Delete';
import Check from '@material-ui/icons/Check';
import ActiveEvent from '@material-ui/icons/TurnedIn';
import NonActiveEvent from '@material-ui/icons/TurnedInNot';
import Cultural from '@material-ui/icons/Favorite';
import { useCulturals } from 'hooks/culturals';
import { useEvents } from 'hooks/events';
import ConfirmationAction from 'components/Modals/ConfirmationAction';
import { getTranslatedField } from 'utils/helpers';

const toggleAlerts = (id) => (previousData) => {
    if (previousData.includes(id)) {
        return [...previousData.filter((_id) => _id !== id)];
    } else {
        return [...previousData, id];
    }
};

function Collapse(props) {
    const { title, id, category_level, custom_id, translations, events_count } = props;

    const {
        setActivePanelCategory,
        setActiveCultural,
        resetCultural,
        clearCulturalImages,
        clearEventMedia,
        setActiveEvent,
        resetEvent,
        activeTab,
        setClassification,
        lang,
        createAlert
    } = useZustandStore();

    const [visible, setVisible] = React.useState(false);

    const [categoryTitle, setCategoryTitle] = React.useState(
        getTranslatedField(translations)(lang, 'title')
    );

    const [customId, setCustomId] = React.useState(custom_id);

    React.useEffect(() => {
        setCategoryTitle(getTranslatedField(translations)(lang, 'title'));
    }, [lang, translations]);

    React.useEffect(() => {
        setCustomId(customId);
    }, [customId]);

    const [isEditingCategory, setIsEditingCategory] = React.useState(null);

    const [culturalAlert, setCulturalAlert] = React.useState([]);
    const [eventAlert, setEventAlert] = React.useState([]);
    const [categoryAlert, setCategoryAlert] = React.useState([]);

    const toggleCulturalAlert = (id) =>
        setCulturalAlert((prev) => toggleAlerts(id)(prev));

    const toggleCategoryAlert = (id) =>
        setCategoryAlert((prev) => toggleAlerts(id)(prev));

    const toggleEventAlert = (id) =>
        setEventAlert((prev) => toggleAlerts(id)(prev));

    const categoryIdToRemove = React.useRef(null);
    const culturalIdToRemove = React.useRef(null);
    const eventIdToRemove = React.useRef(null);

    const toggleVisibility = (e) => {
        if (e?.target?.type !== 'checkbox') {
            return;
        }

        e.stopPropagation();

        const option = {
            [`${category_level}`]: {
                code: category_level >= 3 ? id : custom_id,
                custom_id,
                id
            }
        };

        setActivePanelCategory(option);
        setClassification(activeTab, option);
        resetCultural();
        resetEvent();
        clearCulturalImages();
        clearEventMedia();
        setVisible((prev) => !prev);
    };

    const nextCategory = category_level + 1;
    const nextId = nextCategory >= 4 ? id : custom_id;

    const { data } = useOtherCategories(
        nextId,
        nextCategory,
        nextCategory < 5 && visible
    );

    const { data: culturals } = useCulturals(
        id,
        category_level >= 3 && visible && !props.withEvents
    );

    const { data: events } = useEvents(
        category_level >= 3 && visible && props.withEvents ? id : false
    );

    const onCategoryChange = (evt) => {
        const dataInput = evt.target.id.split('-');

        const key = dataInput[0];
        const _id = +dataInput[1];

        if (key === 'title') {
            setIsEditingCategory(_id);
            setCategoryTitle(evt?.target.value);
        }

        if (key === 'custom_id') {
            const regex = /^\d+$/;

            if (regex.test(evt?.target.value)) {
                setIsEditingCategory(_id);
                setCustomId(evt?.target.value);
            }
        }
    };

    const onValidateCategoryChange = () => {
        if (!categoryTitle || !customId) {
            setIsEditingCategory(null);
            setCategoryTitle(getTranslatedField(translations)(lang, 'title'));
            setCustomId(customId);

            return;
        }

        if (customId.length < 12) {
            createAlert({ type: 'alert-error', message: 'custom id length must be 12!' });

            return;
        }

        props.onUpdateCategory({
            category: id,
            data: {
                title: {
                    [lang]: categoryTitle
                },
                custom_id: customId,
                previous_custom_id: custom_id,
                category_level
            }
        });
    };

    React.useEffect(() => {
        if (!props.isUpdatingCategory) {
            setIsEditingCategory(null);
        }
    }, [lang, props.isUpdatingCategory, translations]);

    const renderCollapse = React.useCallback(
        (item) => (
            <Collapse
                key={item?.id}
                {...item}
                isRemovingEvent={props.isRemovingEvent}
                isUpdatingCategory={props.isUpdatingCategory}
                onUpdateCategory={props.onUpdateCategory}
                withEvents={props.withEvents}
                isRemovingCultural={props.isRemovingCultural}
                removeCultural={props.removeCultural}
                removeEvent={props.removeEvent}
                isRemovingCategory={props.isRemovingCategory}
                removeCategory={props.removeCategory}/>
        ),
        [
            props.isRemovingCategory,
            props.isRemovingCultural,
            props.isRemovingEvent,
            props.isUpdatingCategory,
            props.onUpdateCategory,
            props.removeCategory,
            props.removeCultural,
            props.removeEvent,
            props.withEvents
        ]
    );

    const onCulturalPress = (e, cultural) => {
        e.stopPropagation();
        setActiveCultural(cultural);
        clearCulturalImages();
    };

    const onEventPress = (e, event) => {
        resetEvent();
        e.stopPropagation();
        setActiveEvent(event?.id);
        clearEventMedia();
    };

    const renderCultural = (cultural) => {
        return (
            <div key={cultural.id}>
                <div
                    className='btn-md flex justify-start cursor-pointer items-center'
                    onClick={(e) => onCulturalPress(e, cultural?.id)}>
                    <Cultural fontSize='small'/>
                    <span className='text-brand-secondary font-semibold text-xs pl-3'>
                        {getTranslatedField(cultural.translations)(lang, 'title')}
                    </span>
                    <button
                        className={`btn btn-ghost btn-sm btn-circle absolute right-11 z-40 ${
                            props.isRemovingCultural &&
                            cultural?.id === culturalIdToRemove.current
                                ? 'loading'
                                : ''
                        }`}
                        onClick={() => {
                            toggleCulturalAlert(cultural?.id);
                        }}>
                        {!props.isRemovingCultural ||
                        (props.isRemovingCultural &&
                            cultural.id !== culturalIdToRemove.current) ? (
                                <DeleteIcon fontSize='small' color='action' />
                            ) : null}
                    </button>
                </div>
                {culturalAlert.includes(cultural?.id) ? (
                    <ConfirmationAction
                        denyAction={() => toggleCulturalAlert(cultural?.id)}
                        acceptAction={() => removeCultural(cultural)}/>
                ) : null}
            </div>
        );
    };

    const renderEvents = (event) => {
        return (
            <div key={event.id}>
                <div
                    onClick={(e) => onEventPress(e, event)}
                    className='btn-md flex justify-start cursor-pointer items-center'>
                    {
                        event.status === 'ACTIVE'
                            ? <ActiveEvent fontSize='small'/>
                            : <NonActiveEvent fontSize='small'/>
                    }
                    <span className='text-brand-secondary text-xs font-semibold pl-3'>
                        {getTranslatedField(event.translations)(lang, 'title')}
                    </span>
                    <button
                        className={`btn btn-ghost btn-sm btn-circle absolute right-11 z-40 ${
                            props.isRemovingEvent &&
                            event?.id === eventIdToRemove.current
                                ? 'loading'
                                : ''
                        }`}
                        onClick={() => {
                            toggleEventAlert(event?.id);
                        }}>
                        {!props.isRemovingEvent ||
                        (props.isRemovingEvent &&
                            event.id !== eventIdToRemove.current) ? (
                                <DeleteIcon fontSize='small' color='action' />
                            ) : null}
                    </button>
                </div>
                {eventAlert.includes(event?.id) ? (
                    <ConfirmationAction
                        denyAction={() => toggleEventAlert(event?.id)}
                        acceptAction={() => removeEvent(event)}/>
                ) : null}
            </div>
        );
    };

    const removeCategory = () => {
        toggleCategoryAlert(id);
        props.removeCategory(props);
        categoryIdToRemove.current = id;
    };

    const removeCultural = (cultural) => {
        toggleCulturalAlert(cultural?.id);
        props.removeCultural(cultural);
        culturalIdToRemove.current = cultural.id;
    };

    const removeEvent = (event) => {
        toggleEventAlert(event?.id);
        props.removeEvent(event);
        eventIdToRemove.current = event.id;
    };

    useEffect(() => {
        return () => {
            categoryIdToRemove.current = null;
            culturalIdToRemove.current = null;
        };
    }, []);

    return (
        <div className='relative'>
            <div className='w-full absolute z-50 h-fit'>
                {categoryAlert.includes(id) ? (
                    <ConfirmationAction
                        denyAction={() => toggleCategoryAlert(id)}
                        acceptAction={removeCategory}/>
                ) : null}
            </div>
            <div
                onClick={toggleVisibility}
                className='
                        collapse
                        collapse-arrow
                        border-b
                        flex
                        items-center'>
                <input type='checkbox' className='peer' />
                <div
                    className='
                            collapse-title
                            bg-brand-accent
                            text-primary-content
                            peer-checked:bg-accent
                            peer-checked:text-secondary-content
                            flex
                            items-center'>
                    {CATEGORIES_ICONS[id] ? (
                        <img
                            className='w-6 h-6'
                            src={CATEGORIES_ICONS[id]}
                            alt={id}/>
                    ) : (
                        <div className='w-6 h-6' />
                    )}

                    {category_level === 2 ? (
                        <div className='border-solid rounded-full h-3 w-3 border-brand-primary border-1' />
                    ) : category_level === 3 ? (
                        <div className='rounded-full h-2 w-2 bg-brand-secondary' />
                    ) : null}

                    {
                        category_level === 2
                            ? <span className='text-brand-secondary font-semibold text-sm pl-3'>{custom_id}</span>
                            : category_level === 3
                                ? <input id={'custom_id-' + id}
                                    onChange={onCategoryChange}
                                    value={customId}
                                    maxLength={12}
                                    // disabled={category_level === 2}
                                    contentEditable={false}
                                    placeholder={custom_id}
                                    className='input
                            input-primary
                            input-xs
                            w-24
                            border-none
                            text-brand-secondary
                            font-semibold
                            text-xs
                            mx-1
                            z-40' />
                                : null
                    }

                    {category_level >= 3 ? (
                        <>
                            <input
                                id={'title-' + id}
                                onChange={onCategoryChange}
                                value={categoryTitle}
                                contentEditable={false}
                                placeholder={title}
                                className='
                                    input
                                    input-primary
                                    input-sm
                                    border-none
                                    w-fit
                                    max-w-fit
                                    min-w-fit
                                    text-brand-secondary
                                    font-semibold
                                    text-sm
                                    z-40'/>
                            {(isEditingCategory === id &&
                            (title !== categoryTitle || custom_id !== customId)) ? (
                                    <button
                                        onClick={onValidateCategoryChange}
                                        className={`btn btn-primary focus:btn-primary btn-sm btn-circle z-50 ${
                                        props.isUpdatingCategory
                                            ? 'loading'
                                            : ''
                                    }`}>
                                        {!props.isUpdatingCategory ? (
                                            <Check fontSize='small' />
                                        ) : null}
                                    </button>
                                ) : null}
                        </>
                    ) : (
                        <span
                            className=' text-brand-secondary
                                font-semibold
                                text-sm
                                px-4'>
                            {getTranslatedField(translations)(lang, 'title')}
                        </span>
                    )}

                    {
                        (category_level >= 3 && props.withEvents)
                            ? <span className='
                                    absolute
                                    bg-brand-primary
                                    text-white
                                    font-medium
                                    text-xs
                                    rounded-md
                                    p-1
                                    min-w-[16px]
                                    min-h-[16px]
                                    flex
                                    justify-center
                                    items-center
                                    '>
                                {
                                    events_count
                                }
                            </span>
                            : null
                    }

                    {category_level >= 3 ? (
                        <button
                            onClick={() => toggleCategoryAlert(id)}
                            className={`btn btn-ghost btn-sm btn-circle absolute right-11 z-40 ${
                                props.isRemovingCategory &&
                                id === categoryIdToRemove.current
                                    ? 'loading'
                                    : ''
                            }`}>
                            {!props.isRemovingCategory ||
                            (props.isRemovingCategory &&
                                id !== categoryIdToRemove.current) ? (
                                    <DeleteIcon fontSize='small' color='action' />
                                ) : null}
                        </button>
                    ) : null}
                </div>

                {data && category_level < 5 && visible ? (
                    <div
                        className='
                                collapse-content
                                bg-white
                                text-primary-content
                                peer-checked:bg-secondary
                                peer-checked:text-secondary-content'>
                        {data.map(renderCollapse)}
                    </div>
                ) : null}

                {culturals && visible && !props.withEvents ? (
                    <div className='flex flex-col w-full'>
                        {culturals.map(renderCultural)}
                    </div>
                ) : null}
                {events && visible && props.withEvents ? (
                    <div className='flex flex-col w-full'>
                        {events.map(renderEvents)}
                    </div>
                ) : null}
            </div>
        </div>
    );
}

Collapse.propTypes = {
    title: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    custom_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    category_level: PropTypes.number,
    events_count: PropTypes.number,
    removeCategory: PropTypes.func,
    removeCultural: PropTypes.func,
    onUpdateCategory: PropTypes.func,
    removeEvent: PropTypes.func,
    isRemovingCategory: PropTypes.bool,
    isRemovingCultural: PropTypes.bool,
    isRemovingEvent: PropTypes.bool,
    isUpdatingCategory: PropTypes.bool,
    withEvents: PropTypes.bool,
    translations: PropTypes.array
};

export default Collapse;
