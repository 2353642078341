import React from 'react';
import PropTypes from 'prop-types';

function FileInput(props) {
    const _ref = React.useRef();

    const onChange = evt => {
        props.onChange(evt);
        _ref.current.value = '';
    };

    return (
        <input
            ref={_ref}
            id='media-image'
            onChange={onChange}
            type='file'
            accept='image/*'
            className='file-input file-input-bordered file-input-primary file-input-sm w-full max-w-xs text-brand-secondary'/>
    );
}

FileInput.propTypes = {
    onChange: PropTypes.func
};

export default FileInput;
