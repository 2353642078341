import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/icons/Add';
import { modals } from 'config/constants';

function AddCategory(props) {
    return (
        <label
            id={props.id}
            onClick={props.onClick}
            htmlFor={modals.category}
            className='
                btn
                btn-sm
                btn-primary
                btn-circle
                focus:btn-primary'>
            <div className='pointer-events-none'>
                <Icon
                    style={{
                        color: '#fff'
                    }}/>
            </div>
        </label>

    );
}

AddCategory.propTypes = {
    onClick: PropTypes.func,
    id: PropTypes.string
};

export default AddCategory;
