import React from 'react';
import PropTypes from 'prop-types';
import { useZustandStore } from 'zustandStore';

function Alert(props) {
    const { closeAlert } = useZustandStore();
    const onCloseAlert = () => closeAlert(props.id);

    return (
        <div className={`alert ${props.type} shadow-lg max-w-md min-w-fit m-4`}>
            <div>
                <button onClick={onCloseAlert}>
                    <svg xmlns='http://www.w3.org/2000/svg' className='stroke-current flex-shrink-0 h-6 w-6' fill='none'
                        viewBox='0 0 24 24'><path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'
                            d='M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z' /></svg>
                </button>
                <span>{props.message}</span>
            </div>
        </div>
    );
}

Alert.propTypes = {
    id: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

export default Alert;
