/* eslint-disable camelcase */
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import axios from 'axios';
import { API_URL, STORAGEKEY_AUTH_TOKEN } from 'config/constants';

// import { API_URL, STORAGEKEY_AUTHTOKEN } from '~/config/constants';
import { clearAllLocalStorage, getLocalStorage, removeLocalStorage, setLocalStorage } from 'utils/storage';

let apiTokenAuthorizationInterceptor;
let responseInterceptor;

const axiosInstance = axios.create({
    baseURL: API_URL
});

axiosInstance.interceptors.response.use(response => {
    return response;
}, function(err) {
    if (err.status === 401) {
        // removed for the meantime
        queryClient.setQueryData([endPoints.admin.account], null);
        queryClient.clear();
        clearAllLocalStorage();
    }

    return err;
});

export { axiosInstance };

export const endPoints = {
    auth: {
        login: 'auth/login'
    },
    admin: {
        account: 'me'
    },
    categories: {
        mainCategories: 'cultural/categories/all?category_level=1',
        otherCategories: (parentId, level) => `cultural/categories/all?category_level=${level}&parent=${parentId}&by_code=${level !== 4}&per_page=1000`,
        createCategory: 'cultural/categories',
        deleteCategory: category => `cultural/categories/${category}`,
        update: category => `cultural/categories/${category}`
    },
    events: {
        list: categories => `events?categories=${categories}&per_page=1000`,
        event: event => `events/${event}`,
        create: 'events',
        delete: event => `events/${event}`,
        update: event => `events/${event}`,
        main: 'events/main',
        removeFromMain: event => `events/main/${event}`,
        highlights: 'events/highlights',
        removeFromHighlights: event => `events/highlights/${event}`
    },
    culturals: {
        list: (category, actual_category_only = true) => `cultural/contents?categories=${category}&actual_category_only=${actual_category_only}`,
        create: 'cultural/contents',
        cultural: id => `cultural/contents/${id}`,
        update: culturalContent => `cultural/contents/${culturalContent}`,
        delete: culturalContent => `cultural/contents/${culturalContent}`
    },
    upload: {
        image: 'upload/image'
    },
    customMessages: {
        create: 'custom-messages',
        list: 'custom-messages?order_by_position=1',
        delete: customMessage => `custom-messages/${customMessage}`,
        update: customMessage => `custom-messages/${customMessage}`
    }
};

export function setAuthToken(token) {
    // AsyncStorage.setItem(STORAGEKEY_AUTHTOKEN, token);.
    setLocalStorage(STORAGEKEY_AUTH_TOKEN, token);

    // removing the existing token before adding a new one // for some cases, the token isn't properly removed and the new token isn't set. And it can lead of failed api calls
    axiosInstance.interceptors.request.eject(apiTokenAuthorizationInterceptor);
    axiosInstance.interceptors.response.eject(responseInterceptor);

    apiTokenAuthorizationInterceptor = axiosInstance.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${token || ''}`;

        return config;
    });

    responseInterceptor = axiosInstance
        .interceptors
        .response
        .use((response) => response, async (error) => {
            if (error?.response?.status === 401) {
                unsetAuthToken();
                await removeLocalStorage(STORAGEKEY_AUTH_TOKEN);
            }

            return error;
        });
}

export function unsetAuthToken() {
    axiosInstance.interceptors.request.eject(apiTokenAuthorizationInterceptor);
}

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1000 * 60 * 60 // 1 hour
        }
    }
});

export const queryCache = new QueryCache({
    // onError: error => {
    //     console.log(error);
    // },
    // onSuccess: data => {
    //     console.log(data);
    //     return data;
    // }
});

const storageConfig = {
    setItem: setLocalStorage,
    getItem: getLocalStorage,
    removeItem: removeLocalStorage
};

export const localStoragePersister = createSyncStoragePersister({ storage: storageConfig });

persistQueryClient({
    queryClient,
    persister: localStoragePersister
});
