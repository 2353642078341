import React from 'react';
import { useIllustrationStore } from 'zustandStore/illustrationStore';
import CloseIcon from '@material-ui/icons/Close';
import { useCustomMessagesList, useUpdateCustomMessage } from 'hooks/customMessages';

function CancelUpdateButton() {
    const illustartions = useCustomMessagesList();
    const customMessage = useUpdateCustomMessage();

    const {
        selectedCard,
        textContent,
        linkTitle,
        fontSize,
        textPosition,
        letterSpacing,
        illustartionUrl,
        illustrationPosition,
        linkScreen,
        backgroundColor,
        linkPosition, turnOffUpdateMode, updateMode
    } = useIllustrationStore();

    const handleUpdate = () => {
        const data = {};

        data.id = selectedCard;
        data.background_color = backgroundColor;
        data.illustartion_url = illustartionUrl ?? data.illustartion_url;
        data.illustartion_position = illustrationPosition;
        data.text_content = {
            en: textContent.en?.trim(),
            fr: textContent.fr?.trim()
        };
        data.text_position = textPosition;
        data.text_font_size = fontSize;
        data.text_spacing = letterSpacing;
        data.position = illustartions.data?.find(msg => msg.id === selectedCard)?.position;

        if (linkScreen) {
            data.link_screen = linkScreen;
            data.link_position = linkPosition;
            data.link_title = {
                en: linkTitle.en?.trim(),
                fr: linkTitle.fr?.trim()
            };
        }

        customMessage.mutate(data);
    };

    if (!updateMode) {
        return null;
    }

    return (
        <div className='
        fixed
        right-4
        z-40'>
            <button onClick={handleUpdate} className={`btn
                            btn-primary
                            btn-circle
                            focus:bg-primary
                            flex
                            justify-center
                            items-center
                            text-white
                            w-20
                            h-20
                            lowercase
                            ${customMessage.isLoading ? 'loading' : ''}
                            `}>
                <span>
                        update
                </span>
            </button>
            <button onClick={turnOffUpdateMode} className='
                            btn
                            btn-active
                            btn-circle
                            btn-sm
                            absolute
                            -top-4
                            flex
                            justify-center
                            items-center
                            text-white
                            lowercase'>
                <CloseIcon/>
            </button>
        </div>
    );
}

export default CancelUpdateButton;
