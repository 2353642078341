import React from 'react';
import PropTypes from 'prop-types';
import RightPanel from 'components/RightPanel';
import CategoryManager from 'components/CategoryManager';
import Spinner from 'components/Spinner';
import EventMediaPreview from 'components/EventMediaPreview';
import EventsManager from 'components/EventsManager';
import Tabs from 'components/Tabs';
import SelectedEvents from './SelectedEvents';
import { useHighlightEvents, useMainEvents } from 'hooks/events';
import { useZustandAuth } from 'zustandStore';

function Events(props) {
    const { isAuthed } = useZustandAuth();
    const mainEvents = useMainEvents(isAuthed);
    const highlighEvent = useHighlightEvents(isAuthed);

    return (
        <div className='flex flex-col max-h-screen w-full justify-between lg:flex-row'>
            <div className='h-full flex-col grow'>
                <EventMediaPreview event={props.event}/>
                <SelectedEvents data={mainEvents.data} title={'Featured Events'} id={'is_main_event'}/>
                <SelectedEvents data={highlighEvent.data} title={'Highlight Events'} id={'is_highlight'}/>
            </div>
            <div className='flex-grow max-w-3xl h-fit max-h-fit card justify-end rounded-box place-items-center overflow-hidden border-none shadow-lg'>
                {
                    props.isLoadingEvent
                        ? <div className='absolute w-full h-full z-50 opacity-75 flex justify-center items-center'>
                            <Spinner/>
                        </div>
                        : null

                }
                <div className='flex flex-col min-w-full p-3'>
                    <div className='flex flex-col h-fit w-full card rounded-box border-none'>
                        <Tabs/>
                        <CategoryManager
                            categoryEdit={false}
                            data={props.data}
                            level2data={props.level2data}
                            level3data={props.level3data}
                            level4data={props.level4data}/>
                    </div>
                    <div className='divider' />
                    <div className='grid h-fit card w-full rounded-box place-items-center border-none'>
                        <EventsManager
                            event={props.event}
                            isLoadingCulturals={props.isLoadingCulturals}
                            isPublishing={props.isPublishing}
                            isUpdating={props.isUpdating}
                            onCulturalToggle={props.onCulturalToggle}
                            onEventChange={props.onEventChange}
                            publish={props.publish}
                            submitUpdates={props.submitUpdates}/>
                    </div>
                </div>
            </div>
            <div className='divider lg:divider-horizontal' />
            <div className='grid min-w-fit w-2/6 overflow-y-scroll card rounded-box place-items-start border-none shadow-lg'>
                <RightPanel
                    isUpdatingCategory={props.isUpdatingCategory}
                    onUpdateCategory={props.onUpdateCategory}
                    isRemovingEvent={props.isRemovingEvent}
                    removeEvent={props.removeEvent}
                    withEvents={true}
                    isRemovingCultural={props.isRemovingEvent}
                    isRemovingCategory={props.isRemovingCategory}
                    removeCategory={props.removeCategory}
                    data={props.data}/>
            </div>
        </div>
    );
}

Events.propTypes = {
    data: PropTypes.array,
    level2data: PropTypes.array,
    level3data: PropTypes.array,
    level4data: PropTypes.array,
    removeCategory: PropTypes.func,
    isRemovingCategory: PropTypes.bool,
    isLoadingEvent: PropTypes.bool,
    removeEvent: PropTypes.func,
    isRemovingEvent: PropTypes.bool,
    event: PropTypes.object,
    isLoadingCulturals: PropTypes.bool,
    isPublishing: PropTypes.bool,
    isUpdating: PropTypes.bool,
    onCulturalToggle: PropTypes.func,
    onEventChange: PropTypes.func,
    publish: PropTypes.func,
    submitUpdates: PropTypes.func,
    onUpdateCategory: PropTypes.func,
    isUpdatingCategory: PropTypes.bool
};

export default Events;
