import { axiosInstance, endPoints } from 'api';
import { useMutation, useQuery } from '@tanstack/react-query';

const auth = async ({ email, password }) => {
    const result = await axiosInstance.post(endPoints.auth.login, { email, password });

    if (result?.response?.status === 401) {
        const error = new Error();

        error.message = 'invalid credentials';
        error.result = result?.response;
        error.status = 401;

        throw error;
    }

    return result.data;
};

export const useAuth = () => {
    const { mutate, isLoading } = useMutation(auth);

    return {
        onAuth: mutate,
        isLoading
    };
};

const admin = async () => {
    const result = await axiosInstance.get(endPoints.admin.account);

    return result.data;
};

export const useAdmin = (enabled) => {
    const { isLoading } = useQuery({
        queryFn: admin,
        queryKey: [endPoints.admin.account],
        enabled
    });

    return {
        isLoading
    };
};
