const ART = require('assets/categories/art_icon.png');
const BOOK = require('assets/categories/Reading.png');
const CINEMA = require('assets/categories/Cinema & Series.png');
const CUISINE = require('assets/categories/Cuisine.png');
const CUP = require('assets/categories/cup_icon.png');
const HISTORY = require('assets/categories/history_icon.png');
const LIVE = require('assets/categories/Live SHow.png');
const MAGIC = require('assets/categories/magic_icon.png');
const MUSIC = require('assets/categories/music_icon.png');
const RELAX = require('assets/categories/Relaxation.png');
const SIENCE = require('assets/categories/science_icon.png');
const SPERITUAL = require('assets/categories/spe_icon.png');

/****
 *
 * window & process.env constants
 *
 */
export const ELOQUENT = window.Eloquent;
export const AXIOS = window._axios;

// see https://github.com/SchwSimon/creatella-react-components#withasynccaller
window._withAsyncCallerAxios = window._BaseAxios;

export const APP_ENV = process.env.REACT_APP_ENV;
export const APP_URL = process.env.REACT_APP_URL;
export const API_URL = process.env.REACT_APP_API_URL;

/****
 *
 * Storage keys
 *
 */
export const STORAGEKEY_AUTH_TOKEN = 'sk-authToken';
export const STORAGEKEY_APP_LANGUAGE = 'sk-appLanguage';

/****
 *
 * Configs
 *
 */
// App default language (also used for the <html /> lang='' attribute)
// see https://developer.paciellogroup.com/blog/2016/06/using-the-html-lang-attribute/
// NOTE: make sure the language key defined here matches the default helmet key
// // see `executors/i18n/helmet`
export const APP_LANGUAGE_DEFAULT = 'en'; // TODO_REPLACE(en | de | fr | etc..)

// App environments
// see .env.production & .env.development
// values must match the values defined in these files
export const APP_ENVS = {
    LOCAL: 'local',
    STAGING: 'staging',
    PRODUCTION: 'production'
};

// SENTRY - see https://docs.sentry.io/error-reporting/configuration/?platform=javascript
export const SENTRY_CONFIG = {
    environment: APP_ENV,
    dsn:
    'TODO_REPLACE(sentry dsn url, create a project at https://sentry.io/organizations/creatella-ventures/projects/)'
};

// WEBFONT config - see https://github.com/typekit/webfontloader
export const WEBFONT_CONFIG = {
    google: {
        families: [
            'Poppins:300',
            'Poppins:400',
            'Poppins:500',
            'Poppins:600',
            'Poppins:700',
            'Poppins:900',
            'Montserrat:300',
            'Montserrat:400',
            'Roboto:400',
            'Roboto:500',
            'Roboto:600',
            'Roboto:700'
        ]
    }
};

// Social networks config
export const SOCIAL_NETWORK_URLS = {
    FACEBOOK: 'TODO_REPLACE',
    INSTAGRAM: 'TODO_REPLACE',
    TWITTER: 'TODO_REPLACE',
    LINKEDIN: 'TODO_REPLACE'
    // ...
};

export const FACEBOOK_APP_ID = 'TODO_REPLACE';

/****
 *
 * App routing
 *
 */
export const ROUTES = {
    HOME: '/',
    LOGIN: '/login',
    ADD_EVENT: '/add-event',
    ADD_CONTENT: '/add-content'
};

export const routes = {
    login: '/',
    events: '/events',
    culturals: '/culturals',
    illustrations: '/illustrations'
};

export const ROUTE_REDIRECT_AFTER_REGISTRATION = ROUTES.HOME;
export const ROUTE_REDIRECT_AFTER_LOGIN = ROUTES.HOME;
export const ROUTE_REDIRECT_FROM_UNAUTHED_ROUTE = ROUTES.HOME;
export const ROUTE_REDIRECT_FROM_AUTHED_ROUTE = ROUTES.LOGIN;

/****
 *
 * Project config
 *
 */

// ...

export const modals = {
    category: 'complices-modal-add-category',
    image: 'complices-modal-add-image',
    cover: 'complices-modal-add-cover',
    delete: {
        cultural: 'complices-modal-delete-cultural',
        category: 'complices-modal-delete-category',
        event: 'complices-modal-delete-event'
    }
};

export const MAIN_CATEGORIES = {
    ART: 1,
    READING: 6,
    MUSIC: 14,
    CINEMA_SERIES: 46,
    DANCING_LIVESHOW: 64,
    SCIENCE_TECHNOLOGY: 77,
    SOCIAL_SCIENCE: 84,
    SPIRITUALITY: 96,
    SPORT_GAMES: 107,
    CUISINE: 158,
    CREATIVITY_FASHION: 181,
    RELAXATION_WELLBEING: 191
};

export const CATEGORIES_COLORS = {};
export const CATEGORIES_ICONS = {};

CATEGORIES_COLORS[MAIN_CATEGORIES.DANCING_LIVESHOW] = '#FFA41B';
CATEGORIES_COLORS[MAIN_CATEGORIES.READING] = '#7E7FB2';
CATEGORIES_COLORS[MAIN_CATEGORIES.MUSIC] = '#A286F4';
CATEGORIES_COLORS[MAIN_CATEGORIES.CINEMA_SERIES] = '#94ACE7';
CATEGORIES_COLORS[MAIN_CATEGORIES.SCIENCE_TECHNOLOGY] = '#2EBDDC';
CATEGORIES_COLORS[MAIN_CATEGORIES.CUISINE] = '#FF6645';
CATEGORIES_COLORS[MAIN_CATEGORIES.SPORT_GAMES] = '#4EDCA9';
CATEGORIES_COLORS[MAIN_CATEGORIES.SPIRITUALITY] = '#CA71BE';
CATEGORIES_COLORS[MAIN_CATEGORIES.RELAXATION_WELLBEING] = '#FB7B82';
CATEGORIES_COLORS[MAIN_CATEGORIES.SOCIAL_SCIENCE] = '#5CA1BE';
CATEGORIES_COLORS[MAIN_CATEGORIES.ART] = '#2EC4B5';
CATEGORIES_COLORS[MAIN_CATEGORIES.CREATIVITY_FASHION] = '#FFC500';

CATEGORIES_ICONS[MAIN_CATEGORIES.DANCING_LIVESHOW] = LIVE;
CATEGORIES_ICONS[MAIN_CATEGORIES.READING] = BOOK;
CATEGORIES_ICONS[MAIN_CATEGORIES.MUSIC] = MUSIC;
CATEGORIES_ICONS[MAIN_CATEGORIES.CINEMA_SERIES] = CINEMA;
CATEGORIES_ICONS[MAIN_CATEGORIES.SCIENCE_TECHNOLOGY] = SIENCE;
CATEGORIES_ICONS[MAIN_CATEGORIES.CUISINE] = CUISINE;
CATEGORIES_ICONS[MAIN_CATEGORIES.SPORT_GAMES] = CUP;
CATEGORIES_ICONS[MAIN_CATEGORIES.SPIRITUALITY] = SPERITUAL;
CATEGORIES_ICONS[MAIN_CATEGORIES.RELAXATION_WELLBEING] = RELAX;
CATEGORIES_ICONS[MAIN_CATEGORIES.SOCIAL_SCIENCE] = HISTORY;
CATEGORIES_ICONS[MAIN_CATEGORIES.ART] = ART;
CATEGORIES_ICONS[MAIN_CATEGORIES.CREATIVITY_FASHION] = MAGIC;

export const youtubeBaseUrlPath = 'https://www.youtube.com/watch?v=';
export const youtubeBaseUrlEmbed = 'https://youtu.be/';
