import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const DatePicker = props => {
    const _endDate = useRef();
    const _startDate = useRef();

    const [maxDate, setMaxDate] = useState(props.endDate);
    const [minDate, setMinDate] = useState(props.startDate);

    const showEndDatePicker = () => {
        _endDate.current.showPicker();
    };

    const showStartDatePicker = () => {
        _startDate.current.showPicker();
    };

    useEffect(() => {
        if (props.startDate) {
            _startDate.current.value = props.startDate;
        } else {
            _startDate.current.value = '';
        }

        if (props.endDate) {
            _endDate.current.value = props.endDate;
        } else {
            _endDate.current.value = '';
        }
    }, [props.endDate, props.startDate]);

    const onChangeStartDate = evt => {
        setMinDate(evt.target.value);
        props.onEventChange(evt);
    };

    const onChangeEndDate = evt => {
        setMaxDate(evt.target.value);
        props.onEventChange(evt);
    };

    return (
        <div className='flex py-6 space-x-6'>
            <div className='flex flex-col space-y-2'>
                <span className='text-brand-secondary text-sm font-semibold'>Start Date</span>
                <input
                    className='btn btn-primary focus:btn-primary'
                    onChange={onChangeStartDate}
                    type='date'
                    id='start_date'
                    ref={_startDate}
                    // value={props.startDate}
                    max={maxDate}
                    onClick={showStartDatePicker}/>
            </div>
            <div className='flex flex-col space-y-2'>
                <span className='text-brand-secondary text-sm font-semibold'>End Date</span>
                <input
                    onChange={onChangeEndDate}
                    ref={_endDate}
                    min={minDate}
                    // value={props.endDate}
                    onClick={showEndDatePicker}
                    className='btn btn-primary focus:btn-primary'
                    type='date'
                    id='end_date'/>
            </div>
        </div>
    );
};

DatePicker.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    onEventChange: PropTypes.func
};

export default DatePicker;
