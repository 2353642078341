/* eslint-disable camelcase */
import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosInstance, endPoints, queryClient } from 'api';
import { useEffect, useState } from 'react';
import { getTranslatedField } from 'utils/helpers';
import { useZustandStore } from 'zustandStore';

const createCultural = async (data) => {
    const result = await axiosInstance.post(endPoints.culturals.create, data);

    if (!result.status || result.status !== 201) {
        const error = new Error();

        error.message = result.result?.data?.message ?? 'Could not create the cultural!';

        throw error;
    }

    return result.data;
};

export const useCreateCultural = () => {
    const { createAlert } = useZustandStore();
    const { mutate, isLoading } = useMutation({
        mutationFn: createCultural,
        mutationKey: [endPoints.culturals.create],
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({
                queryKey: [endPoints.culturals.list(result?.parent_id)]
            });
            createAlert({
                type: 'alert-success',
                message: `The cultural ${result?.title} has been created!`
            });
        },
        onError: ({ message }) => {
            createAlert({
                type: 'alert-error',
                message
            });
        }
    });

    return {
        onCreateCultural: mutate,
        isLoading
    };
};

export async function getCulturals(category, actual_category_only = true) {
    const response = await axiosInstance.get(
        endPoints.culturals.list(category, actual_category_only)
    );

    return response.data?.data;
}

export const useCulturals = (category, enabled) => {
    const { data, isLoading } = useQuery({
        queryFn: () => getCulturals(category),
        queryKey: [endPoints.culturals.list(category)],
        enabled
    });

    return {
        data,
        isLoading
    };
};

async function getCultural(id) {
    const response = await axiosInstance.get(endPoints.culturals.cultural(id));

    return response.data;
}

async function getEvents(categories) {
    const result = await axiosInstance.get(endPoints.events.list(categories));

    return result?.data?.data;
}

export const useCulturalsEvents = (id, categories, saveEvents, enabled) => {
    const [culturalEvents, setCulturalEvents] = useState([]);
    const [categoryEvents, setCategoryEvents] = useState([]);

    const {
        data: cultural,
        isFetching: isFetchCultural,
        isLoading
    } = useQuery({
        queryKey: [endPoints.culturals.cultural(id)],
        queryFn: () => getCultural(id),
        enabled,
        onSuccess: (res) =>
            setCulturalEvents(
                res.events.map((evt) => ({ ...evt, checked: true })) ?? []
            )
    });

    const { isFetching: isFetchingCategoryEvents } = useQuery({
        queryFn: () => getEvents(categories),
        queryKey: [endPoints.events.list(categories)],
        enabled: !!categories,
        onSuccess: (res) =>
            setCategoryEvents(res.map((evt) => ({ ...evt, checked: false })))
    });

    useEffect(() => {
        saveEvents(prev => {
            return [
                ...prev.filter((evt) => evt?.checked),
                ...categoryEvents
            ].reduce((acc, curr) => {
                if (!acc.find((evt) => evt?.id === curr?.id)) {
                    acc.push(curr);
                }

                return acc;
            }, []);
        });
    }, [categoryEvents, saveEvents]);

    useEffect(() => {
        if (!cultural) {
            saveEvents([]);
        } else {
            saveEvents(culturalEvents);
        }
    }, [culturalEvents, saveEvents, cultural]);

    return {
        cultural,
        isLoadingCultural: isFetchCultural && isLoading,
        isFetchingCategoryEvents
    };
};

async function updateCultural({ id, data, old_parent_id }) {
    const response = await axiosInstance.put(
        endPoints.culturals.update(id),
        data
    );

    if (!response.status || response.status !== 200) {
        const error = new Error();

        error.message = response.response?.data?.message ?? 'Could not update the cultural!';

        throw error;
    }

    response.data.old_parent_id = old_parent_id;

    return response.data;
}

export const useUpdateCultural = () => {
    const { createAlert, lang } = useZustandStore();
    const { mutate: onUpdateCultural, isLoading: isUpdatingCultural } =
        useMutation({
            mutationFn: updateCultural,
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [
                        endPoints.culturals.list(res?.parent_id)
                    ]
                });

                if (res?.old_parent_id !== res?.parent_id) {
                    await queryClient.invalidateQueries({
                        queryKey: [
                            endPoints.culturals.list(res?.old_parent_id)
                        ]
                    });
                }

                createAlert({
                    type: 'alert-success',
                    message: `The cultural ${getTranslatedField(res.translations)(lang, 'title')} has been updated!`
                });
            },
            onError: ({ message }) => {
                createAlert({
                    type: 'alert-error',
                    message
                });
            }
        });

    return {
        onUpdateCultural,
        isUpdatingCultural
    };
};

async function deleteCultural(cultural) {
    await axiosInstance.delete(endPoints.culturals.delete(cultural.id));

    return cultural;
}

export const useDeleteCultural = () => {
    const { createAlert, resetCultural } = useZustandStore();
    const { mutate: onDeleteCultural, isLoading: isDeletingCultural } =
        useMutation({
            mutationFn: deleteCultural,
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [endPoints.culturals.list(res?.parent_id)]
                });
                resetCultural();
                createAlert({
                    type: 'alert-success',
                    message: `The cultural ${res?.title} has been deleted!`
                });
            }
        });

    return {
        onDeleteCultural,
        isDeletingCultural
    };
};
