import React from 'react';
import PropTypes from 'prop-types';

function CheckBox(props) {
    return (
        <div className='form-control'>
            <label className='cursor-pointer label flex items-center justify-start space-x-3'>
                <input
                    id={props.id}
                    type='checkbox'
                    checked={props.checked}
                    onChange={props.onChange}
                    className='checkbox checkbox-primary'/>
                <span className='label-text text-brand-secondary font-medium'>
                    {props.label}
                </span>
            </label>
        </div>
    );
}

CheckBox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string,
    id: PropTypes.number
};

export default CheckBox;
