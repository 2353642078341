import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosInstance, endPoints, queryClient } from 'api';
import { useZustandStore } from 'zustandStore';
import { useIllustrationStore } from 'zustandStore/illustrationStore';

async function createCustomMessage(data) {
    const response = await axiosInstance.post(endPoints.customMessages.create, data);

    if (!response.status || response.status !== 201) {
        const error = new Error();

        const resErr = Object.values(response?.response?.data.errors ?? {})?.[0]?.[0];

        error.message = resErr ?? response.response?.data?.message ?? 'Could not create message!';

        throw error;
    }

    return response.data;
}

export const useCreateCustomMessage = () => {
    const { createAlert } = useZustandStore();
    const query = useMutation(createCustomMessage, {
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [endPoints.customMessages.list]
            });
        },
        onError: ({ message }) => {
            createAlert({
                type: 'alert-error',
                message
            });
        }
    });

    return query;
};

async function updateCustomMessage(data) {
    const id = data.id;

    delete data.id;
    const response = await axiosInstance.put(endPoints.customMessages.update(id), data);

    if (!response.status || response.status > 201) {
        const error = new Error();

        const resErr = Object.values(response?.response?.data.errors ?? {})?.[0]?.[0];

        error.message = resErr ?? response.response?.data?.message ?? 'Could not update message!';

        throw error;
    }

    return response.data;
}

export const useUpdateCustomMessage = () => {
    const { createAlert } = useZustandStore();
    const { turnOffUpdateMode } = useIllustrationStore();
    const query = useMutation(updateCustomMessage, {
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [endPoints.customMessages.list]
            });

            turnOffUpdateMode();
        },
        onError: ({ message }) => {
            createAlert({
                type: 'alert-error',
                message
            });
        }
    });

    return query;
};

async function getCustomMessages() {
    const response = await axiosInstance.get(endPoints.customMessages.list);

    return response.data.data;
}

export const useCustomMessagesList = () => {
    const query = useQuery({
        queryFn: getCustomMessages,
        queryKey: [endPoints.customMessages.list]
    });

    return query;
};

async function deleteCustomMessage(id) {
    const response = await axiosInstance.delete(endPoints.customMessages.delete(id));

    return response.data.data;
}

export const useDeleteCustomMessage = () => {
    const query = useMutation(deleteCustomMessage,
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: [endPoints.customMessages.list]
                });
            }
        });

    return query;
};
