import React from 'react';
import PropTypes from 'prop-types';

function TextHolder(props) {
    return (
        <span className='text text-brand-secondary font-semibold text-md'>
            {
                props.children
            }
        </span>
    );
}

TextHolder.propTypes = {
    children: PropTypes.node
};

export default TextHolder;
