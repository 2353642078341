import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export function useTextFormatter() {
    const formatText = (string) => {
        const stringParts = string?.split(/(\*|~|\n)/);

        let isEmStartTag = false;
        let isStrongStartTag = false;
        const elemets = [];

        for (let i = 0; i < stringParts?.length; i++) {
            const part = stringParts[i];

            if (stringParts[i] === '~' && !isEmStartTag) {
                isEmStartTag = true;
                continue;
            }

            if (stringParts[i] === '*' && !isStrongStartTag) {
                isStrongStartTag = true;
                continue;
            }

            if (isEmStartTag && isStrongStartTag) {
                if (part === '~') {
                    isEmStartTag = false;
                    continue;
                } else if (part === '*') {
                    isStrongStartTag = false;
                    continue;
                }

                elemets.push(<em key={uuidv4()}>
                    <strong>{stringParts[i]}</strong>
                </em>);
            } else if (isEmStartTag) {
                if (part === '~') {
                    isEmStartTag = false;
                    continue;
                }

                elemets.push(<em key={uuidv4()}>
                    {
                        stringParts[i]
                    }
                </em>);
            } else if (isStrongStartTag) {
                if (part === '*') {
                    isStrongStartTag = false;
                    continue;
                }

                elemets.push(<strong key={uuidv4()}>
                    {
                        stringParts[i]
                    }
                </strong>);
            } else if (part === '\n') {
                elemets.push(<br key={uuidv4()} />);
            } else {
                elemets.push(<span key={uuidv4()}>
                    {
                        part
                    }
                </span>);
            }
        }

        return elemets;
    };

    return { formatText };
}
