import { create } from 'zustand';

const initialState = {
    culturalContent: []
};

export const useLinkedCulturalStore = create((set) => ({
    ...initialState,
    addCulturalContent: (cltrl) =>
        set((prevStore) => ({
            culturalContent: [...prevStore.culturalContent, cltrl]
        })),
    putCulturalContent: (culturalContent) =>
        set(() => ({
            culturalContent
        })),
    toggleCulturalContent: (id) =>
        set((prevStore) => ({
            culturalContent: prevStore.culturalContent.map((cltrl) => ({
                ...cltrl,
                checked: id === cltrl.id ? !cltrl.checked : cltrl.checked
            }))
        })),
    resetCulturalContent: () => set(() => initialState)
}));
