import React, { useState } from 'react';
import {
    useMainCategories,
    useOtherCategories,
    useRemoveCategory,
    useUpdateCategory
} from 'hooks/categories';
import Culturals from 'screens/Culturals';
import withAlert from 'containers/HOCs/withAlert';
import { useZustandStore } from 'zustandStore';
import { useCulturalsEvents, useDeleteCultural } from 'hooks/culturals';
import useNewCategory from 'hooks/useNewCategory';
import useNewCultural from 'hooks/useNewCultural';

function CulturalsContainer(props) {
    const { activeCategory, activeCultural } = useZustandStore();
    const categories = Object.values(activeCategory)
        ?.map((category) => category?.id)
        ?.join(',');

    const { isDeletingCultural, onDeleteCultural } = useDeleteCultural();

    const [culturalEvents, setCulturalEvents] = useState([]);

    const { cultural, isLoadingCultural, isFetchingCategoryEvents } = useCulturalsEvents(activeCultural, categories, setCulturalEvents, !!activeCultural);

    const {
        cropUrl,
        imgFile,
        imgUrl,
        isLoadingCropImg,
        isLoadingFullImg,
        onCropComplete,
        onCulturalChange,
        onEventToggle,
        onImageCrop,
        onSelectImage,
        onSubmitCultural,
        handleOnCulturalUpdate,
        isUpdatingCultural,
        isPublishingCultural
    } = useNewCultural(setCulturalEvents, cultural);

    const {
        isAddingCategory,
        newCategoryLevel,
        onChangeCategoryInput,
        onSetNewCategoryLevel,
        onSubmitCategory
    } = useNewCategory();

    const { data, isLoading } = useMainCategories();

    const { data: level2data } = useOtherCategories(
        activeCategory['1']?.code,
        2,
        !!activeCategory['1']?.code
    );
    const { data: level3data } = useOtherCategories(
        activeCategory['2']?.code,
        3,
        !!activeCategory['2']?.code
    );
    const { data: level4data } = useOtherCategories(
        activeCategory['3']?.code,
        4,
        !!activeCategory['3']?.code
    );

    const { isLoading: isRemovingCategory, onRemoveCategory } =
        useRemoveCategory();

    const { isUpdatingCategory, onUpdateCategory } = useUpdateCategory();

    const handleRemoveCategory = (category) => {
        onRemoveCategory(category);
    };

    if (isLoading) {
        return <div>...loading</div>;
    }

    return (
        <Culturals
            cultural={cultural}
            isLoadingCultural={isLoadingCultural}
            events={culturalEvents}
            isAddingCategory={isAddingCategory}
            level2data={level2data}
            level3data={level3data}
            level4data={level4data}
            newCategoryLevel={newCategoryLevel}
            onChangeCategoryInput={onChangeCategoryInput}
            onSetNewCategoryLevel={onSetNewCategoryLevel}
            onSubmitCategory={onSubmitCategory}
            isRemovingCategory={isRemovingCategory}
            removeCategory={handleRemoveCategory}
            data={data}
            onEventToggle={onEventToggle}
            onSelectImage={onSelectImage}
            onCulturalChange={onCulturalChange}
            submit={onSubmitCultural}
            imgFile={imgFile}
            imgUrl={imgUrl}
            cropUrl={cropUrl}
            cropImage={onImageCrop}
            onCropComplete={onCropComplete}
            isLoadingCropImg={isLoadingCropImg}
            isLoadingEvents={isFetchingCategoryEvents}
            isLoadingFullImg={isLoadingFullImg}
            // isUpdating={isUp}
            isRemovingCultural={isDeletingCultural}
            removeCultural={onDeleteCultural}
            isUpdating={isUpdatingCultural}
            submitUpdates={handleOnCulturalUpdate}
            isPublishing={isPublishingCultural}
            isUpdatingCategory={isUpdatingCategory}
            onUpdateCategory={onUpdateCategory}/>
    );
}

CulturalsContainer.propTypes = {};

export default withAlert(CulturalsContainer);
