import React from 'react';
import PropTypes from 'prop-types';
import { useMatch, useNavigate } from 'react-router-dom';

function NavbarBtn(props) {
    const { title, pathname } = props;
    const navigate = useNavigate();
    const isActive = !!useMatch(pathname);

    const onNavigate = () => navigate(pathname);

    return (
        <button
            onClick={onNavigate}
            className={`
            btn 
            btn-accent 
            focus:btn-accent 
            focus:text-brand-primary 
            font-bold 
            text-sm 
            ${isActive ? 'text-brand-primary' : 'text-brand-secondary'}`}>
            {title}
        </button>
    );
}

NavbarBtn.propTypes = {
    title: PropTypes.string,
    pathname: PropTypes.string
};

export default NavbarBtn;
