import React from 'react';
import { routes } from 'config/constants';
import NavbarBtn from 'components/NavbarBtn';
import { useZustandStore } from 'zustandStore';

function NavBar(props) {
    const { lang, setLanguage } = useZustandStore();

    const onLanguageChange = evt => {
        setLanguage(evt.target.id);
    };

    return (
        <div
            className='flex justify-between items-center p-3 shadow-sm max-w-full mb-6'>
            <div className=''>
                <span
                    className='
                    btn
                    btn-ghost
                    normal-case
                    text-xl
                    text-brand-secondary'>
                        Complices
                </span>
            </div>
            <div
                className='space-x-3'>
                <NavbarBtn title='Content' pathname={routes.culturals}/>
                <NavbarBtn title='Events' pathname={routes.events}/>
                <NavbarBtn title='Illustrations' pathname={routes.illustrations}/>
            </div>
            <div className=''>
                <div className='dropdown'>
                    <label tabIndex={0} className='btn btn-primary focus:btn-primary m-1'>{lang}</label>
                    <ul tabIndex={0} className='dropdown-content menu p-2 shadow bg-base-100 rounded-box'>
                        <li>
                            <button className='btn btn-accent' onClick={onLanguageChange} id='fr'>
                                Fr
                            </button>
                        </li>
                        <li>
                            <button className='btn btn-accent' onClick={onLanguageChange} id='en'>
                                En
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

NavBar.propTypes = {};

export default NavBar;
