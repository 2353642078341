import React from 'react';
import PropTypes from 'prop-types';
import Collapse from 'components/Collapase';

function RightPanel(props) {
    const { data } = props;
    const renderCollapse = React.useCallback(
        (item) => (
            <Collapse
                isUpdatingCategory={props.isUpdatingCategory}
                onUpdateCategory={props.onUpdateCategory}
                removeEvent={props.removeEvent}
                isRemovingEvent={props.isRemovingEvent}
                withEvents={props.withEvents}
                isRemovingCultural={props.isRemovingCultural}
                removeCultural={props.removeCultural}
                isRemovingCategory={props.isRemovingCategory}
                removeCategory={props.removeCategory}
                key={item?.id}
                {...item}/>
        ),
        [
            props.isRemovingCategory,
            props.isRemovingCultural,
            props.isRemovingEvent,
            props.isUpdatingCategory,
            props.onUpdateCategory,
            props.removeCategory,
            props.removeCultural,
            props.removeEvent,
            props.withEvents
        ]
    );

    return (
        <div className='artboard w-full p-1'>{data?.map(renderCollapse)}</div>
    );
}

RightPanel.propTypes = {
    data: PropTypes.array,
    removeCategory: PropTypes.func,
    isRemovingCategory: PropTypes.bool,
    withEvents: PropTypes.bool,
    isRemovingCultural: PropTypes.bool,
    isRemovingEvent: PropTypes.bool,
    onUpdateCategory: PropTypes.func,
    isUpdatingCategory: PropTypes.bool,
    removeCultural: PropTypes.func,
    removeEvent: PropTypes.func
};

export default RightPanel;
