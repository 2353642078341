import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from '../ModalWrapper';

function AddCategoryModal(props) {
    const _frRef = useRef();
    const _enRef = useRef();

    useEffect(() => {
        if (props.isLoading) {
            _frRef.current.value = '';
            _enRef.current.value = '';
        }
    }, [props.isLoading]);

    return (
        <ModalWrapper id={props.id}>

            <div className='flex flex-col space-y-6 items-center w-full'>
                <span className='text text-brand-secondary text-lg'>
                    you are about to add new category of level {props.level}
                </span>

                <input
                    ref={_frRef}
                    id='fr'
                    onChange={props.onChange}
                    type='text'
                    placeholder='Category name in French'
                    className='
                        input
                        input-bordered
                        input-primary
                        w-full
                        max-w-xs' />
                <input
                    ref={_enRef}
                    id='en'
                    onChange={props.onChange}
                    type='text'
                    placeholder='Category name in English'
                    className='
                        input
                        input-bordered
                        input-primary
                        w-full
                        max-w-xs' />
                <button
                    onClick={props.onSubmit}
                    className={`btn
                        btn-primary
                        focus:btn-primary
                        text-brand-accent
                        focus:text-brand-accent
                        ${props.isLoading ? 'loading' : ''}`}>
                            add category
                </button>
            </div>

        </ModalWrapper>
    );
}

AddCategoryModal.propTypes = {
    level: PropTypes.number,
    isLoading: PropTypes.bool,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    id: PropTypes.string
};

export default AddCategoryModal;
