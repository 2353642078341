import FileInput from 'components/FileInput';
import InputHolder from 'components/InputHolder';
import TextHolder from 'components/TextHolder';
import TextareaHolder from 'components/TextareaHolder';
import {
    useCreateCustomMessage,
    useCustomMessagesList
} from 'hooks/customMessages';
import { useUploadImage } from 'hooks/upload';
import React from 'react';
import { SketchPicker } from 'react-color';
import {
    FONT_SIZES,
    LINK_SCREENS,
    POSITIONS,
    useIllustrationStore
} from 'zustandStore/illustrationStore';

function IllustrationManager() {
    const customMessage = useCreateCustomMessage();
    const illustartions = useCustomMessagesList();

    const {
        setBackgroundColor,
        setTextContent,
        setFontSize,
        setTextPosition,
        setLetterSpacing,
        setIllustartionUrl,
        setIllustrationPosition,
        setLinkScreen,
        setLinkPosition,
        setLinkTitle,
        backgroundColor,
        textContent,
        linkTitle,
        fontSize,
        textPosition,
        letterSpacing,
        illustartionUrl,
        illustrationPosition,
        linkScreen,
        linkPosition,
        updateMode
    } = useIllustrationStore();

    const { onUploadImage } = useUploadImage();

    const onSubmit = () => {
        const data = {};

        data.background_color = backgroundColor;
        data.illustartion_url = illustartionUrl;
        data.illustartion_position = illustrationPosition;
        data.text_content = {
            en: textContent.en?.trim(),
            fr: textContent.fr?.trim()
        };
        data.text_position = textPosition;
        data.text_font_size = fontSize;
        data.text_spacing = letterSpacing;
        data.position = illustartions.data?.length;

        if (linkScreen) {
            data.link_screen = linkScreen;
            data.link_position = linkPosition;
            data.link_title = {
                en: linkTitle.en?.trim(),
                fr: linkTitle.fr?.trim()
            };
        }

        customMessage.mutate(data);
    };

    const handleSelectImage = ({ target }) => {
        var reader = new FileReader();

        reader.onload = (event) => {
            onUploadImage(event.target.result, {
                onSuccess: (result) => {
                    setIllustartionUrl(result);
                }
            });
        };

        reader.readAsDataURL(target.files[0]);
    };

    const handleChangeComplete = (color) => {
        setBackgroundColor(color.hex);
    };

    const onTextContentChange = ({ target: { value, id } }) => {
        setTextContent({
            lang: id,
            value
        });
    };

    const onLinkNameChange = ({ target: { value, id } }) => {
        setLinkTitle({
            lang: id,
            value
        });
    };

    const renderFontSizeOption = (size) => {
        return (
            <option
                selected={size === fontSize}
                value={size}
                key={size}>
                {size}
            </option>
        );
    };

    const onFontSizeChange = ({ target: { value } }) => {
        setFontSize(+value);
    };

    const renderTextPositionOption = (position) => {
        return (
            <option
                selected={position === textPosition}
                value={position}
                key={position}>
                {position}
            </option>
        );
    };

    const renderIllustartionPositionOption = (position) => {
        return (
            <option
                selected={position === illustrationPosition}
                value={position}
                key={position}>
                {position}
            </option>
        );
    };

    const onTextPositionChange = ({ target: { value } }) => {
        setTextPosition(value);
    };

    const onIllustartionPositionChange = ({ target: { value } }) => {
        setIllustrationPosition(value);
    };

    const onWordSpacingChange = ({ target: { value } }) => {
        setLetterSpacing(+value);
    };

    const onLinkScreenChange = ({ target: { value } }) => {
        setLinkScreen(value);
    };

    const onLinkPositionChange = ({ target: { value } }) => {
        setLinkPosition(value);
    };

    const renderLinkScreenOption = (screen) => {
        return (
            <option
                selected={screen === linkScreen}
                value={screen}
                key={screen}>
                {screen}
            </option>
        );
    };

    const renderLinkPositionOption = (position) => {
        return (
            <option
                selected={position === linkPosition}
                value={position}
                key={position}>
                {position}
            </option>
        );
    };

    return (
        <div className='card'>
            <div className='form-control space-y-3 border-none'>
                <TextHolder>Pick Background Color</TextHolder>
                <SketchPicker
                    color={backgroundColor}
                    onChangeComplete={handleChangeComplete}/>

                <TextHolder>Pick An Image</TextHolder>
                <FileInput onChange={handleSelectImage} />

                <TextHolder>Illustration Position</TextHolder>
                <select
                    className='select'
                    name={'text_font_size'}
                    onChange={onIllustartionPositionChange}>
                    <option
                        disabled={true}
                        // selected={!props.selected}
                        hidden={true}>
                        {/* {props.placeHolder} */}
                    </option>
                    {POSITIONS?.map(renderIllustartionPositionOption)}
                </select>

                <TextHolder>Text</TextHolder>
                <TextareaHolder
                    defaultValue={updateMode && textContent.en}
                    placeholder={'for bold style, wrap your text within a * \nfor italic style, wrap your text within a ~'}
                    onChange={onTextContentChange}
                    id='en'/>
                <TextareaHolder
                    defaultValue={updateMode && textContent.fr}
                    placeholder={'pour un style gras, placez votre texte entre un * \npour un style italique, placez votre texte entre un ~'}
                    onChange={onTextContentChange}
                    id='fr'/>
                <TextHolder>Text Font Size</TextHolder>
                <select
                    className='select'
                    name={'text_font_size'}
                    onChange={onFontSizeChange}>
                    <option
                        disabled={true}
                        // selected={!props.selected}
                        hidden={true}>
                        {/* {props.placeHolder} */}
                    </option>
                    {FONT_SIZES?.map(renderFontSizeOption)}
                </select>

                <TextHolder>Text Spacing</TextHolder>
                <InputHolder
                    defaultValue={updateMode && letterSpacing}
                    min={-38}
                    type='number'
                    placeholder='Spacing...'
                    onChange={onWordSpacingChange}
                    id='text_content'/>

                <TextHolder>Text Position</TextHolder>
                <select
                    className='select'
                    name={'text_font_size'}
                    onChange={onTextPositionChange}>
                    <option
                        disabled={true}
                        // selected={!props.selected}
                        hidden={true}>
                        {/* {props.placeHolder} */}
                    </option>
                    {POSITIONS?.map(renderTextPositionOption)}
                </select>

                <TextHolder>Link Screen</TextHolder>
                <select
                    className='select'
                    name={'text_font_size'}
                    onChange={onLinkScreenChange}>
                    <option disabled={true} selected={false} hidden={true}>
                        {/* {props.placeHolder} */}
                    </option>
                    {LINK_SCREENS?.map(renderLinkScreenOption)}
                </select>

                <TextHolder>Link Position</TextHolder>
                <select
                    className='select'
                    name={'text_font_size'}
                    onChange={onLinkPositionChange}>
                    <option
                        disabled={true}
                        // selected={!props.selected}
                        hidden={true}>
                        {/* {props.placeHolder} */}
                    </option>
                    {POSITIONS?.map(renderLinkPositionOption)}
                </select>

                <TextHolder>Link Name</TextHolder>
                <InputHolder
                    defaultValue={updateMode && linkTitle.en}
                    placeholder='English...'
                    onChange={onLinkNameChange}
                    id='en'/>
                <InputHolder
                    defaultValue={updateMode && linkTitle.fr}
                    placeholder='French...'
                    onChange={onLinkNameChange}
                    id='fr'/>
            </div>

            <button
                onClick={onSubmit}
                disabled={illustartions.data?.length >= 2 || updateMode}
                className={`btn
                    btn-primary
                    focus:btn-primary
                    text-brand-accent
                    focus:text-brand-accent ${
                        customMessage.isLoading ? 'loading' : ''
                    }`}>
                Submit
            </button>
        </div>
    );
}

export default IllustrationManager;
