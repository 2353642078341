import React from 'react';
import { useZustandStore } from 'zustandStore';
import { useAddCategory } from './categories';

function useNewCategory() {
    const { activeCategory, createAlert, clearAlerts } = useZustandStore();
    const { isLoading, onAddCategory } = useAddCategory();
    const newCategory = React.useRef({
        fr: '',
        en: ''
    });

    const [newCategoryLevel, setNewCategoryLevel] = React.useState();

    const onSubmitCategory = () => {
        let message = '';

        if (!Object.keys(activeCategory)?.length) {
            message = 'You have not selected any category!';
        } else if (
            !Object.keys(activeCategory).includes(
                (newCategoryLevel - 1).toString()
            )
        ) {
            message = `Level ${
                newCategoryLevel - 1
            } category must be selected as parent!`;
        } else if (!newCategory.current.fr) {
            message = 'French translation is required';
        } else if (!newCategory.current.en) {
            message = 'English translation is required';
        }

        if (message) {
            createAlert({ type: 'alert-error', message });

            return;
        }

        clearAlerts();
        const data = {};

        data.title = newCategory.current;
        data.parent_id = activeCategory[newCategoryLevel - 1]?.id;
        data.category_level = newCategoryLevel;

        onAddCategory(data);

        newCategory.current = {
            fr: '',
            en: ''
        };
    };

    const onChangeCategoryInput = (evt) => {
        const { id, value } = evt.target;

        newCategory.current[id] = value;
    };

    const onSetNewCategoryLevel = (evt) => {
        const level = evt?.target?.id === 'category_level_3' ? 3 : 4;

        setNewCategoryLevel(level);
    };

    return {
        isAddingCategory: isLoading,
        onChangeCategoryInput,
        onSetNewCategoryLevel,
        onSubmitCategory,
        newCategoryLevel
    };
}

export default useNewCategory;
