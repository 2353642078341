import TextHolder from 'components/TextHolder';
import { useCustomMessagesList, useUpdateCustomMessage } from 'hooks/customMessages';
import React, { useEffect, useMemo, useState } from 'react';
import Illustration from './Illustration';
import { Reorder } from 'framer-motion';

function IllustrationsList() {
    const illustartions = useCustomMessagesList();

    const data = useMemo(() => illustartions.data, [illustartions.data]);

    const [stateData, setStateData] = useState([]);

    useEffect(() => {
        setStateData(data);
    }, [data]);

    const customMessage = useUpdateCustomMessage();

    const renderIllustration = illustartion => (
        <Illustration key={illustartion.id} illustartion={illustartion}/>
    );

    const handleReorder = (reorderedData) => {
        setStateData(reorderedData);

        reorderedData.map((data, idx) => {
            const _data = { ...data, position: idx + 1 };

            _data.text_content = {
                en: data.translations?.find(translation => translation.translatable_field === 'text_content' && translation.lang === 'en')?.content,
                fr: data.translations?.find(translation => translation.translatable_field === 'text_content' && translation.lang === 'fr')?.content
            };

            _data.link_title = {
                en: data.translations?.find(translation => translation.translatable_field === 'link_title' && translation.lang === 'en')?.content,
                fr: data.translations?.find(translation => translation.translatable_field === 'link_title' && translation.lang === 'fr')?.content
            };

            return customMessage.mutate(_data);
        });
    };

    return (
        <div className='p-4 space-y-6'>
            <TextHolder>Created Illustrations</TextHolder>
            <Reorder.Group className='p-4 space-y-6' axis='y' values={stateData}
                onReorder={handleReorder}>
                {
                    stateData?.map(renderIllustration)
                }
            </Reorder.Group>
        </div>
    );
}

export default IllustrationsList;
