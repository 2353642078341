import withAlert from 'containers/HOCs/withAlert';
import React from 'react';
import IllustrationManager from './IllustrationManager';
import IllustrationPreview from './IllustrationPreview';
import IllustrationsList from './IllustrationsList';
import CancelUpdateButton from './CancelUpdateButton';

function IllustrationsContainer() {
    return (
        <div className='grid grid-cols-3'>
            <CancelUpdateButton/>
            <IllustrationsList/>
            <IllustrationPreview/>
            <IllustrationManager/>
        </div>
    );
}

export default withAlert(IllustrationsContainer);
