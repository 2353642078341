import React from 'react';
import PropTypes from 'prop-types';
import { useZustandStore } from 'zustandStore';
import { getFullSizeFormat, getTileFormat } from 'utils/helpers';

function CulturalMediaPreview(props) {
    const { culturalImages } = useZustandStore();
    const fullSize =
        culturalImages.full ?? getFullSizeFormat(props?.cultural?.media);
    const tile = culturalImages?.tile ?? getTileFormat(props?.cultural?.media);

    return (
        <div className='flex flex-row space-x-6 mx-6'>
            <div className='artboard phone-1 shadow-lg rounded-md artboard-demo relative'>
                {fullSize ? (
                    <img
                        className='w-full object-cover'
                        alt='full size preview'
                        src={fullSize}/>
                ) : null}
                <div className='flex h-100px w-100px shadow-lg rounded-md overflow-hidden absolute -right-3 -top-3'>
                    {tile ? (
                        <img
                            className='w-full object-cover'
                            alt='1x1 preview'
                            src={tile}/>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

CulturalMediaPreview.propTypes = {
    cultural: PropTypes.object
};

export default CulturalMediaPreview;
