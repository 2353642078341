import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Selector from 'components/Selector';
import { useZustandStore } from 'zustandStore';
import AddCategory from 'components/AddCategory';
import AddCategoryModal from 'components/Modals/AddCatgoryModal';
import { modals } from 'config/constants';
import { getTranslatedField } from 'utils/helpers';

const nullCategory = {
    category_level: 1,
    id: null,
    custom_id: null,
    title: null,
    translations: [
        {
            content: null,
            id: 2,
            lang: 'en',
            translatable_field: 'title'
        },
        {
            content: null,
            id: 2,
            lang: 'fr',
            translatable_field: 'title'
        }
    ]
};

function CategoryManager(props) {
    const {
        activeCategory,
        setActiveCategory,
        isCategoryManagerActive,
        setActiveManagerCategory,
        activePanelCategory,
        lang,
        activeTab,
        setClassification,
        classification
    } = useZustandStore();

    const renderOption = (category) => {
        const selectedCategory = props.categoryEdit
            ? activeCategory[category?.category_level]
            : classification[activeTab]?.[category?.category_level];

        const selected =
            category?.category_level < 3
                ? selectedCategory?.code === category?.custom_id
                : selectedCategory?.code === category?.id;

        return (
            <option
                selected={selected}
                value={getTranslatedField(category?.translations)(
                    lang,
                    'title'
                )}
                key={category?.id}>
                {getTranslatedField(category?.translations)(lang, 'title')}
            </option>
        );
    };

    const onChange = (option) => {
        setActiveCategory(option);
        setClassification(activeTab, option);
        setActiveManagerCategory();
    };

    useEffect(() => {
        if (!isCategoryManagerActive) {
            setActiveCategory(activePanelCategory);
        }
    }, [activePanelCategory, isCategoryManagerActive, setActiveCategory]);

    const otherTabs = Object.keys(classification).filter(tab => tab !== activeTab);

    const otherMainIds = otherTabs.map(tab => {
        return classification?.[tab]?.[1]?.id;
    });

    const filteredMainCategory = props.data?.reduce((acc, curr) => {
        if (!otherMainIds.find(id => id === curr.id)) {
            acc.push(curr);
        }

        return acc;
    }, []);

    return (
        <div className='flex flex-col space-y-3'>
            <div className='flex space-x-3'>
                <Selector
                    selected={props.categoryEdit
                        ? activeCategory['1']
                        : !!classification[activeTab]?.['1']}
                    name='category_level_1'
                    placeHolder='Category 1'
                    renderOption={renderOption}
                    onChange={onChange}
                    data={activeTab === 'class_1' ? filteredMainCategory : [
                        nullCategory,
                        ...filteredMainCategory
                    ]}/>
                <Selector
                    selected={props.categoryEdit
                        ? activeCategory['2']
                        : !!classification[activeTab]?.['2']}
                    name='category_level_2'
                    placeHolder='Category 2'
                    renderOption={renderOption}
                    onChange={onChange}
                    data={props.level2data}/>
                <Selector
                    selected={props.categoryEdit
                        ? activeCategory['2.5']
                        : !!classification[activeTab]?.['2.5']}
                    // isFetching={isFetchingL2}
                    name='category_level_2.5'
                    placeHolder='Category 2.5'
                    renderOption={renderOption}
                    onChange={onChange}/>
            </div>

            <div className='flex flex-col space-y-3'>
                <div className='space-x-3'>
                    <Selector
                        selected={props.categoryEdit
                            ? activeCategory['3']
                            : !!classification[activeTab]?.['3']}
                        name='category_level_3'
                        placeHolder='Category 3'
                        renderOption={renderOption}
                        onChange={onChange}
                        data={props.level3data}/>
                    {props.categoryEdit ? (
                        <AddCategory
                            id='category_level_3'
                            onClick={props.onSetNewCategoryLevel}/>
                    ) : null}
                </div>
                <div className='space-x-3'>
                    <Selector
                        selected={props.categoryEdit
                            ? activeCategory['4']
                            : !!classification[activeTab]?.['4']}
                        name='category_level_4'
                        placeHolder='Category 4'
                        renderOption={renderOption}
                        onChange={onChange}
                        data={props.level4data}/>
                    {props.categoryEdit ? (
                        <AddCategory
                            id='category_level_4'
                            onClick={props.onSetNewCategoryLevel}/>
                    ) : null}
                </div>
            </div>
            <AddCategoryModal
                id={modals.category}
                isLoading={props.isAddingCategory}
                onChange={props.onChangeCategoryInput}
                level={props.newCategoryLevel}
                onSubmit={props.onSubmitCategory}/>
        </div>
    );
}

CategoryManager.propTypes = {
    data: PropTypes.array,
    level2data: PropTypes.array,
    level3data: PropTypes.array,
    level4data: PropTypes.array,
    onSubmitCategory: PropTypes.func,
    isAddingCategory: PropTypes.bool,
    onChangeCategoryInput: PropTypes.func,
    newCategoryLevel: PropTypes.number,
    onSetNewCategoryLevel: PropTypes.func,
    categoryEdit: PropTypes.bool
};

CategoryManager.defaultProps = {
    categoryEdit: true
};

export default CategoryManager;
