import React from 'react';
import PropTypes from 'prop-types';

function TextareaHolder(props) {
    const ref = React.useRef();

    React.useEffect(() => {
        if (props.defaultValue) {
            ref.current.value = props.defaultValue;
        } else {
            ref.current.value = '';
        }
    }, [props.defaultValue]);

    React.useEffect(() => {
        if (!props.clear) {
            ref.current.value = '';
        }
    }, [props.clear]);

    return (
        <textarea
            ref={ref}
            id={props.id}
            onPaste={props.onChange}
            onChange={props.onChange}
            value={props.value}
            placeholder={props.placeholder}
            className='textarea textarea-primary textarea-bordered textarea-sm w-full max-w-xs h-80'/>
    );
}

TextareaHolder.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    id: PropTypes.string,
    clear: PropTypes.bool
};

export default TextareaHolder;
