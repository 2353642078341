/* eslint-disable camelcase */
import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosInstance, endPoints, queryClient } from 'api';
import { getTranslatedField } from 'utils/helpers';
import { useZustandStore } from 'zustandStore';

async function getMainCategories() {
    const result = await axiosInstance.get(endPoints.categories.mainCategories);

    return result?.data?.data;
}

export const useMainCategories = () => {
    const { data, isLoading } = useQuery(
        [endPoints.categories.mainCategories],
        getMainCategories
    );

    return {
        data,
        isLoading
    };
};

async function getOtherCategories(parentId, level) {
    const result = await axiosInstance.get(
        endPoints.categories.otherCategories(parentId, level)
    );

    return result?.data?.data;
}

export const useOtherCategories = (parentId, level, enabled) => {
    const { data, isLoading, isFetching } = useQuery({
        queryFn: () => getOtherCategories(parentId, level),
        queryKey: [endPoints.categories.otherCategories(parentId, level)],
        enabled
    });

    return {
        data,
        isLoading,
        isFetching
    };
};

async function addCategory(data) {
    const result = await axiosInstance.post(
        endPoints.categories.createCategory,
        data
    );

    return result.data;
}

export const useAddCategory = () => {
    const { createAlert } = useZustandStore();
    const { mutate: onAddCategory, isLoading } = useMutation({
        mutationFn: addCategory,
        mutationKey: [endPoints.categories.createCategory],
        onSuccess: async (result) => {
            const parentId =
                result?.custom_id?.slice(0, 4) || result?.parent_id;

            await queryClient.invalidateQueries({
                queryKey: [
                    endPoints.categories.otherCategories(
                        parentId,
                        result?.category_level
                    )
                ]
            });
            createAlert({
                type: 'success',
                message: `The category ${result?.title} has been created!`
            });
        }
    });

    return {
        onAddCategory,
        isLoading
    };
};

async function removeCategory(category) {
    const result = await axiosInstance.delete(
        endPoints.categories.deleteCategory(category?.id)
    );

    return result;
}

export const useRemoveCategory = () => {
    const { createAlert } = useZustandStore();
    const { mutate: onRemoveCategory, isLoading } = useMutation({
        mutationFn: removeCategory,
        mutationKey: [endPoints.categories.deleteCategory],
        onSuccess: async (_, category) => {
            const parentId =
                category?.custom_id?.slice(0, 4) || category?.parent_id;

            await queryClient.invalidateQueries({
                queryKey: [
                    endPoints.categories.otherCategories(
                        parentId,
                        category?.category_level
                    )
                ]
            });

            createAlert({ type: 'alert-success', message: `${category?.title} has been removed!` });
        }
    });

    return {
        onRemoveCategory,
        isLoading
    };
};

async function updateCategory({ category, data }) {
    const result = await axiosInstance.put(
        endPoints.categories.update(category), data
    );

    return result;
}

export const useUpdateCategory = () => {
    const { createAlert, lang } = useZustandStore();
    const { mutate: onUpdateCategory, isLoading: isUpdatingCategory } = useMutation({
        mutationFn: updateCategory,
        mutationKey: [endPoints.categories.update],
        onSuccess: async ({ data: category }, { data: { previous_custom_id, custom_id } }) => {
            const parentId =
                category?.custom_id?.slice(0, 4) || category?.parent_id;

            const IDs = [parentId];

            if (previous_custom_id !== custom_id) {
                const prevParentId =
                previous_custom_id.slice(0, 4);

                IDs.push(prevParentId);
            }

            await Promise.all(IDs.map(async id => {
                await queryClient.invalidateQueries({
                    queryKey: [
                        endPoints.categories.otherCategories(
                            id,
                            category?.category_level
                        )
                    ]
                });
            }));

            createAlert({ type: 'alert-success', message: `${getTranslatedField(category?.translations)(lang, 'title')} has been updated!` });
        }
    });

    return {
        onUpdateCategory,
        isUpdatingCategory
    };
};
