import React from 'react';
import PropTypes from 'prop-types';

function Login(props) {
    const { onChange, onSubmit, isLoading } = props;

    return (
        <div className='absolute inset-0 flex items-center'>
            <div className='container form-control space-y-6 max-w-md py-6'>
                <label className='input-group input-group-vertical'>
                    <span className='text-brand-secondary text-lg font-semibold'>Email</span>
                    <input onChange={onChange} id='email' type='email'
                        className='input input-bordered text-brand-secondary text-lg font-medium' />
                </label>
                <label className='input-group input-group-vertical'>
                    <span className='text-brand-secondary text-lg font-semibold'>Passowrd</span>
                    <input onChange={onChange} id='password' type='password'
                        className='input input-bordered text-brand-secondary text-lg font-medium' />
                </label>
                <button
                    onClick={onSubmit}
                    className={`btn btn-secondary text-brand-accent focus:btn-secondary ${isLoading ? 'loading' : ''}`}>Login</button>
            </div>
        </div>
    );
}

Login.propTypes = {
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool
};

export default Login;
