import React from 'react';
import { useZustandStore } from 'zustandStore';
import useImagePicker from './useImagePicker';
import { useCreateCultural, useUpdateCultural } from './culturals';
import { getTranslatedField } from 'utils/helpers';
import { useDataForm } from './useDataForm';

const createImages = (...urls) => {
    const [imgUrl, cropUrl] = urls;
    const img = new Image();

    img.src = imgUrl;

    return [
        {
            type: 'IMAGE',
            url: imgUrl,
            metadata: {
                size: 'full',
                width: img?.width,
                height: img?.height
            }
        },
        {
            type: 'IMAGE',
            url: cropUrl,
            metadata: {
                size: '1x1'
            }
        }
    ];
};

const validateCultural = ({
    activeCategory,
    newCultural,
    media
}) => {
    let message = '';

    if (!Object.keys(activeCategory)?.length) {
        message = 'You have not selected any category!';
    } else if (
        !Object.keys(activeCategory).includes('3') &&
        !Object.keys(activeCategory).includes('4')
    ) {
        message =
            'You must publish the cultural under level 3 or 4 category';
    } else if (
        !newCultural.title.en ||
        !newCultural.title.fr
    ) {
        message = 'All title fields are required';
    } else if (
        !newCultural.description.en ||
        !newCultural.description.fr
    ) {
        message = 'All description fields are required';
    } else if (!media.length) {
        message = 'You should add an image';
    }

    return message;
};

function useNewCultural(updateCulturalEvents, cultural) {
    const { isLoading: isPublishingCultural, onCreateCultural } =
        useCreateCultural();
    const { isUpdatingCultural, onUpdateCultural } = useUpdateCultural();
    const {
        imgFile,
        isLoadingCropImg,
        isLoadingFullImg,
        pickImage,
        cropImage
    } = useImagePicker();
    const { activeCategory, createAlert, culturalImages } = useZustandStore();
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);

    const _selectedEventsIDs = React.useRef([]);
    const events = React.useRef([]);

    const { dataForm: newCultural, createData: createCulturalForm, clearData: clearCulturalForm } = useDataForm();
    const { dataForm: title, clearData: clearTitle, createData: createTitle } = useDataForm();
    const { dataForm: description, clearData: clearDescription, createData: createDescription } = useDataForm();

    const onImageCrop = (callback) => {
        cropImage(imgFile, croppedAreaPixels, callback);
    };

    const onSelectImage = (evt) => {
        if (evt?.target?.files) {
            pickImage(evt?.target?.files[0]);
        }
    };

    const onCropComplete = (_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const onCulturalChange = (evt) => {
        const idData = evt?.target?.id?.split('-');
        const { value: inputValue } = evt.target;
        const pastedValue = evt.clipboardData?.getData('text/plain') ?? '';

        const value = inputValue || pastedValue;

        const key = idData[0];

        if (idData.length > 1) {
            const subKey = idData[1];

            if (key === 'title') {
                createTitle(subKey, value);
            }

            if (key === 'description') {
                createDescription(subKey, value);
            }
        } else {
            createCulturalForm(key, value);
        }
    };

    const onEventToggle = (evt) => {
        const { id } = evt.target;

        if (_selectedEventsIDs.current?.includes(id)) {
            _selectedEventsIDs.current = _selectedEventsIDs.current.filter(
                (_id) => _id !== id
            );
            events.current = events.current.map((evt) => ({
                ...evt,
                checked: evt?.id === +id ? false : evt?.checked
            }));
            updateCulturalEvents((prev) =>
                prev.map((evt) => ({
                    ...evt,
                    checked: evt?.id === +id ? false : evt?.checked
                }))
            );
        } else {
            _selectedEventsIDs.current.push(id);
            events.current = events.current.map((evt) => ({
                ...evt,
                checked: evt?.id === +id ? true : evt?.checked
            }));

            updateCulturalEvents((prev) =>
                prev.map((evt) => ({
                    ...evt,
                    checked: evt?.id === +id ? true : evt?.checked
                }))
            );
        }
    };

    const createCulturalData = () => {
        const media = [];

        const { full, tile } = culturalImages;

        if (full && tile) {
            const images = createImages(full, tile);

            media.push(...images);
        } else if (cultural && cultural.media.length) {
            media.push(...cultural.media);
        }

        let culturalData = {};

        const newCulturalData = JSON.parse(JSON.stringify(newCultural ?? {}));
        const newCulturalTitle = JSON.parse(JSON.stringify(title ?? {}));
        const newCulturalDescription = JSON.parse(JSON.stringify(description ?? {}));

        if (cultural) {
            const pureCultural = {};

            for (const [key, value] of Object.entries(cultural)) {
                if (value) {
                    Object.assign(pureCultural, { [key]: value });
                }
            }

            culturalData = { ...pureCultural, ...newCulturalData, title: newCulturalTitle, description: newCulturalDescription };

            if (!culturalData.description.fr) {
                culturalData.description.fr = getTranslatedField(cultural.translations)('fr', 'description');
            }

            if (!culturalData.description.en) {
                culturalData.description.en = getTranslatedField(cultural.translations)('en', 'description');
            }

            if (!culturalData.title.fr) {
                culturalData.title.fr = getTranslatedField(cultural.translations)('fr', 'title');
            }

            if (!culturalData.title.en) {
                culturalData.title.en = getTranslatedField(cultural.translations)('en', 'title');
            }
        } else {
            culturalData = { ...newCulturalData, title: newCulturalTitle, description: newCulturalDescription };
        }

        const message = validateCultural({
            activeCategory,
            newCultural: culturalData,
            media
        });

        if (message) {
            createAlert({ type: 'alert-error', message });

            return;
        }

        const events = _selectedEventsIDs.current?.map((id) => ({ id }));

        if (cultural && !events.length) {
            events.push(...cultural.events.map(evt => ({ id: evt.id })));
        }

        const activeCategoryArr = [...Object.values(activeCategory)];
        const parentCategory = activeCategoryArr.pop();

        const data = {
            ...culturalData,
            parent_id: parentCategory.id,
            events,
            category_level: 5,
            status: 'ACTIVE'
        };

        if (media.length) {
            data.media = media;
        }

        if (!data.total_likes) {
            data.total_likes = 0;
        }

        return data;
    };

    const onSubmitCultural = () => {
        const data = createCulturalData();

        if (data) {
            onCreateCultural(data);
            clearCulturalForm();
            clearDescription();
            clearTitle();
        }
    };

    const handleOnCulturalUpdate = () => {
        const data = createCulturalData();

        if (data) {
            onUpdateCultural({ data, id: cultural?.id, old_parent_id: cultural?.parent_id });
            clearCulturalForm();
            clearDescription();
            clearTitle();
        }
    };

    return {
        onEventToggle,
        onSelectImage,
        onCulturalChange,
        onSubmitCultural,
        imgFile,
        imgUrl: culturalImages.full,
        cropUrl: culturalImages.tile,
        onImageCrop,
        onCropComplete,
        isLoadingCropImg,
        isLoadingFullImg,
        handleOnCulturalUpdate,
        isPublishingCultural,
        isUpdatingCultural
        // events: events.current
    };
}

useNewCultural.propTypes = {};

export default useNewCultural;
