/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useZustandStore } from 'zustandStore';

function Tabs(props) {
    const {
        activeTab,
        setActiveTab
    } = useZustandStore();

    const onSelectTab = (evt) => {
        setActiveTab(evt?.target?.id);
    };

    return (
        <div className='tabs tabs-boxed'>
            <a
                id='class_1'
                onClick={onSelectTab}
                className={`tab ${activeTab === 'class_1' ? 'tab-active' : ''} font-semibold text-brand-secondary hover:text-brand-primary`}>
                Class 1
            </a>
            <a
                id='class_2'
                onClick={onSelectTab}
                className={`tab ${activeTab === 'class_2' ? 'tab-active' : ''} font-semibold text-brand-secondary hover:text-brand-primary`}>
                Class 2
            </a>
            <a
                id='class_3'
                onClick={onSelectTab}
                className={`tab ${activeTab === 'class_3' ? 'tab-active' : ''} font-semibold text-brand-secondary hover:text-brand-primary`}>
                Class 3
            </a>
            <a
                id='class_4'
                onClick={onSelectTab}
                className={`tab ${activeTab === 'class_4' ? 'tab-active' : ''} font-semibold text-brand-secondary hover:text-brand-primary`}>
                Class 4
            </a>
        </div>
    );
}

Tabs.propTypes = {};

export default Tabs;
