import React from 'react';
import PropTypes from 'prop-types';
import { useUploadImage } from './upload';
import { getCroppedImg } from 'utils/helpers';
import { useZustandStore } from 'zustandStore';

function useImagePicker(props) {
    const { isLoading: isLoadingFullImg, onUploadImage: onUploadFullImg } = useUploadImage();
    const { isLoading: isLoadingCropImg, onUploadImage: onUploadCropImg } = useUploadImage();
    const [imgUrl, setImgUrl] = React.useState(null);
    const [cropUrl, setCropUrl] = React.useState(null);
    const [imgFile, setImgFile] = React.useState(null);
    const { setCulturalImages, setEventMedia } = useZustandStore();

    const pickImage = (file, isEvent = false) => {
        var reader = new FileReader();

        reader.onload = event => {
            setImgFile(event.target.result);
            onUploadFullImg(event.target.result, {
                onSuccess: result => {
                    setImgUrl(result);

                    if (isEvent) {
                        setEventMedia({ img: result });
                    } else {
                        setCulturalImages({ full: result });
                    }
                }
            });
        };

        reader.readAsDataURL(file);
    };

    const uploadImage = file => {
        // onUploadImage(file, {
        //     onSuccess: result => {
        //         // setImgUrl(result);
        //     }
        // });
    };

    const cropImage = async (imageFile, croppedAreaPixels, callback) => {
        try {
            const croppedImage = await getCroppedImg(
                imageFile,
                croppedAreaPixels
            );

            onUploadCropImg(croppedImage, {
                onSuccess: result => {
                    setCropUrl(result);
                    setCulturalImages({ tile: result });

                    if (callback) {
                        callback();
                    }
                }
            });
        } catch (e) {
            console.error(e);
        }
    };

    return {
        pickImage,
        imgUrl,
        imgFile,
        isLoadingCropImg,
        isLoadingFullImg,
        uploadImage,
        cropImage,
        cropUrl
    };
}

useImagePicker.propTypes = {};

export default useImagePicker;
