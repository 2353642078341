import React, { useRef } from 'react';
import Login from 'screens/Login';
import { useAuth } from 'hooks/auth';
import { useZustandAuth, useZustandStore } from 'zustandStore';
import { useNavigate } from 'react-router-dom';
import { routes } from 'config/constants';
import withAlert from 'containers/HOCs/withAlert';
import { setAuthToken } from 'api';

function LoginContainer(props) {
    const { isLoading, onAuth } = useAuth();
    const { authorize } = useZustandAuth();
    const { createAlert } = useZustandStore();
    const navigate = useNavigate();
    const authScheme = useRef({
        email: '',
        password: ''
    });

    const onChange = evt => {
        authScheme.current[evt.target.id] = evt.target.value;
    };

    const onSuccess = (result) => {
        setAuthToken(result?.access_token);
        authorize();
        navigate(routes.culturals);
    };

    const onSubmit = () => {
        onAuth(authScheme.current, {
            onSuccess,
            onError: err => {
                createAlert({ type: 'alert-error', message: err?.message });
            }
        });
    };

    return (
        <Login isLoading={isLoading} onChange={onChange} onSubmit={onSubmit}/>
    );
}

LoginContainer.propTypes = {};

export default withAlert(LoginContainer);
