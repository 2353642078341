import React from 'react';
import PropTypes from 'prop-types';
import NavBar from 'components/Navbar';

function Main(props) {
    return (
        <div>
            <NavBar/>
            {
                props.children
            }
        </div>
    );
}

Main.propTypes = {
    children: PropTypes.element
};

export default Main;
