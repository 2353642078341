/* eslint-disable react/prop-types */
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { getTranslatedField } from 'utils/helpers';
import { useZustandStore } from 'zustandStore';
import { useRemoveFromHighlightEvents, useRemoveFromMainEvents } from 'hooks/events';

function SelectedEvent({ event, id }) {
    const { lang } = useZustandStore();
    const removeFromHighlights = useRemoveFromHighlightEvents();
    const removeFromMainEvents = useRemoveFromMainEvents();

    const handleRemoveFromHighlights = async () => {
        if (id === 'is_highlight') {
            removeFromHighlights.mutate({ event: event.id });
        } else {
            removeFromMainEvents.mutate({ event: event.id });
        }
    };

    return (
        <div className='flex items-center'>
            <span className='flex-1 text-brand-secondary text-xs font-semibold'>
                {getTranslatedField(event.translations)(lang, 'title')}
            </span>
            <button
                className={`btn btn-ghost btn-sm btn-circle ${
                    (removeFromHighlights.isLoading | removeFromMainEvents.isLoading) ? 'loading' : ''
                }`}
                onClick={handleRemoveFromHighlights}>
                {!(removeFromHighlights.isLoading | removeFromMainEvents.isLoading) ? (
                    <DeleteIcon fontSize='small' color='action' />
                ) : null}
            </button>
        </div>
    );
}

SelectedEvent.propTypes = {};

export default SelectedEvent;
