import TextHolder from 'components/TextHolder';
import { useTextFormatter } from 'hooks/useTextFormatter';
import React from 'react';
import { useZustandStore } from 'zustandStore';
import {
    POSITIONS,
    useIllustrationStore
} from 'zustandStore/illustrationStore';

function IllustrationPreview() {
    const {
        backgroundColor,
        textContent,
        linkTitle,
        fontSize,
        textPosition,
        letterSpacing,
        illustartionUrl,
        linkScreen,
        linkPosition
    } = useIllustrationStore();
    const { lang } = useZustandStore();

    const { formatText } = useTextFormatter();

    const formattedText = formatText(textContent[lang]);

    return (
        <div className='p-4 space-y-4'>
            <TextHolder>
                Preview
            </TextHolder>
            <div
                style={{
                    backgroundColor,

                    flexDirection:
                        textPosition === POSITIONS[0] ? 'row' : 'row-reverse'
                }}
                className='
                flex
                fixed
                w-[379px]
                h-[127px]
                rounded-[6px]
                text-white
                p-3'>
                <div
                    style={{
                        paddingRight: textPosition === POSITIONS[0] ? 16 : 0,
                        paddingLeft: textPosition === POSITIONS[1] ? 16 : 0
                    }}
                    className='flex-1 flex flex-col'>
                    <div style={{
                        letterSpacing,
                        flex: 1,
                        fontSize
                    }}>
                        {
                            formattedText
                        }
                    </div>
                    {
                        linkScreen
                            ? <span
                                style={{
                                    alignSelf:
                                linkPosition === POSITIONS[0]
                                    ? 'flex-start'
                                    : 'flex-end'
                                }}
                                className='
                    border-2
                    rounded-md
                    p-1
                    hover:cursor-pointer'>
                                {linkTitle[lang]}
                            </span>
                            : null
                    }
                </div>
                {
                    illustartionUrl
                        ? <img
                            className='
                                pointer-events-none
                                h-[136px]
                                w-[136px]
                                -translate-y-[26px]
                                object-contain'
                            src={illustartionUrl}
                            alt=''/>
                        : <div className='
                                    h-[136px]
                                    w-[136px]
                                    -translate-y-[26px]
                                    border-2
                                    rounded-md
                                    border-primary
                                    border-dashed
                                    '/>
                }
            </div>
        </div>
    );
}

export default IllustrationPreview;
