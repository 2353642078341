import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoginContainer from 'containers/Login/LoginContainer';
import ProtectedRoute from 'containers/ProtectedRoute';
import Events from 'containers/Events';
import { routes, STORAGEKEY_AUTH_TOKEN } from 'config/constants';
import PublicRoute from 'containers/PublicRoute';
import { getLocalStorage } from 'utils/storage';
import { setAuthToken } from 'api';
import { useAdmin } from 'hooks/auth';
import CulturalsContainer from 'containers/Culturals';
import IllustrationsContainer from 'containers/Illustrations';
import { useZustandAuth } from 'zustandStore';

const router = createBrowserRouter([
    {
        errorElement: <div>error</div>
    },
    {
        path: routes.culturals,
        element: <ProtectedRoute><CulturalsContainer/></ProtectedRoute>
    },
    {
        path: routes.events,
        element: <ProtectedRoute><Events/></ProtectedRoute>
    },
    {
        path: routes.illustrations,
        element: <ProtectedRoute><IllustrationsContainer/></ProtectedRoute>
    },
    {
        path: routes.login,
        element: <PublicRoute><LoginContainer/></PublicRoute>
    }
]);

function AppContainer() {
    const [enabled, setEnabled] = useState(false);
    const { authorize } = useZustandAuth();

    useAdmin(enabled);

    useEffect(() => {
        const getToken = async () => {
            const token = getLocalStorage(STORAGEKEY_AUTH_TOKEN);

            if (token) {
                authorize();
                setAuthToken(token);
                setEnabled(true);
            }
        };

        getToken();
    }, [authorize]);

    return (
        <RouterProvider router={router}/>
    );
}

AppContainer.propTypes = {};

export default AppContainer;
