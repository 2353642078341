/* eslint-disable react/prop-types */
import React from 'react';
import SelectedEvent from './SelectedEvent';

function SelectedEvents({ title, data, id }) {
    const renderEvent = (event) => (
        <SelectedEvent key={event.id} id={id} event={event}/>
    );

    return (
        <div className='h-fit mt-12 mx-4 p-4 shadow-lg rounded-md'>
            <span className='block text-brand-secondary text-base font-bold pb-2'>
                {title}
            </span>
            {data?.map(renderEvent)}
        </div>
    );
}

export default SelectedEvents;
