import React from 'react';
import PropTypes from 'prop-types';
import TextHolder from 'components/TextHolder';
import InputHolder from 'components/InputHolder';
import TextareaHolder from 'components/TextareaHolder';
import CheckBox from 'components/CheckBox';
import AddImage from 'components/AddImage';
import Spinner from 'components/Spinner';
import { getTranslatedField } from 'utils/helpers';

function CulturalsManager(props) {
    const renderEvents = (event) => (
        <CheckBox
            checked={event?.checked}
            key={event?.id}
            id={event?.id}
            label={event?.title}
            onChange={props.onEventToggle}/>
    );

    return (
        <div className='w-full'>
            <div className='form-control space-y-3 border-none'>
                <TextHolder>Cultural Media</TextHolder>
                <div className='flex items-center space-x-3'>
                    <AddImage />
                </div>
            </div>

            <div className='form-control space-y-3 border-none'>
                <TextHolder>Cultural Title</TextHolder>
                <div className='flex space-x-6'>
                    <InputHolder
                        defaultValue={getTranslatedField(props.cultural?.translations)('fr', 'title')}
                        placeholder='Fr...'
                        onChange={props.onCulturalChange}
                        id='title-fr'/>
                    <InputHolder
                        defaultValue={getTranslatedField(props.cultural?.translations)('en', 'title')}
                        placeholder='En...'
                        onChange={props.onCulturalChange}
                        id='title-en'/>
                </div>
            </div>

            <div className='form-control space-y-3 border-none'>
                <TextHolder>Cultural Description</TextHolder>
                <div className='flex space-x-6'>
                    <TextareaHolder
                        defaultValue={getTranslatedField(props.cultural?.translations)('fr', 'description')}
                        placeholder='Fr...'
                        onChange={props.onCulturalChange}
                        id='description-fr'/>
                    <TextareaHolder
                        defaultValue={getTranslatedField(props.cultural?.translations)('en', 'description')}
                        placeholder='En...'
                        onChange={props.onCulturalChange}
                        id='description-en'/>
                </div>
            </div>

            <div className='form-control space-y-3 border-none'>
                <InputHolder
                    defaultValue={props.cultural?.copy_right}
                    placeholder='Copy right...'
                    onChange={props.onCulturalChange}
                    id='copy_right'/>
                <InputHolder
                    defaultValue={props.cultural?.total_likes + ''}
                    placeholder='Number of likes...'
                    type='number'
                    onChange={props.onCulturalChange}
                    id='total_likes'/>
                <div className='min-h-8'>
                    {
                        props.isLoadingEvents
                            ? <Spinner/>
                            : null
                    }
                    {props.events?.map(renderEvents)}
                    {
                        !props.events?.length
                            ? <span>No linked events</span>
                            : null
                    }
                </div>
            </div>

            <div className='w-full flex justify-end pt-6 space-x-10'>
                <button
                    disabled={!props?.cultural}
                    onClick={props.submitUpdates}
                    className={`btn btn-primary focus:btn-primary text-brand-accent focus:text-brand-accent ${
                        props.isUpdating ? 'loading' : ''
                    }`}>
                    Update Cultural
                </button>
                <button
                    onClick={props.submit}
                    className={`btn btn-primary focus:btn-primary text-brand-accent focus:text-brand-accent ${
                        props.isPublishing ? 'loading' : ''
                    }`}>
                    Publish Cultural
                </button>
            </div>
        </div>
    );
}

CulturalsManager.propTypes = {
    events: PropTypes.array,
    onEventToggle: PropTypes.func,
    submit: PropTypes.func,
    submitUpdates: PropTypes.func,
    isPublishing: PropTypes.bool,
    isLoadingEvents: PropTypes.bool,
    isUpdating: PropTypes.bool,
    onCulturalChange: PropTypes.func,
    cultural: PropTypes.object
};

export default CulturalsManager;
