import React from 'react';
import PropTypes from 'prop-types';
import { modals } from 'config/constants';

function AddImage(props) {
    return (
        <label
            htmlFor={modals.image}
            className='
                btn
                btn-sm
                btn-primary
                focus:btn-primary
                text-brand-accent
                focus:text-brand-accent'>
            <span>Add Image</span>
        </label>
    );
}

AddImage.propTypes = {};

export default AddImage;
