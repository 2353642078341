import React from 'react';
import PropTypes from 'prop-types';

const InputHolder = (props) => {
    const ref = React.useRef();

    React.useEffect(() => {
        if (props.defaultValue) {
            ref.current.value = props.defaultValue;
        } else {
            ref.current.value = '';
        }
    }, [props.defaultValue]);

    React.useEffect(() => {
        if (!props.clear) {
            ref.current.value = '';
        }
    }, [props.clear]);

    return (
        <input
            onPaste={props.onChange}
            min={props.min ?? 0}
            ref={ref}
            id={props.id}
            type={props.type}
            placeholder={props.placeholder}
            onChange={props.onChange}
            value={props.value}
            className='input input-primary input-bordered input-sm w-full max-w-xs'/>
    );
};

InputHolder.defaultProps = {
    type: 'text'
};

InputHolder.propTypes = {
    placeholder: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.oneOf(['text', 'number', 'tel']),
    clear: PropTypes.bool,
    min: PropTypes.number
};

export default InputHolder;
