import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from '../ModalWrapper';
import FileInput from 'components/FileInput';
import Cropper from 'react-easy-crop';
import Spinner from 'components/Spinner';

function AddImageModal(props) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [isCropperVisible, setCropperVisibility] = useState(false);

    useEffect(() => {
        setCropperVisibility(!!props.imgFile);
    }, [props.imgFile]);

    const toggleCropperVisibility = () => setCropperVisibility((prev) => !prev);

    const handleCropping = () => {
        props.cropImage(toggleCropperVisibility);
    };

    return (
        <ModalWrapper id={props.id}>
            <div className='space-y-6'>
                <div>
                    <div className='flex w-full h-fit justify-center items-center'>
                        <div className='grid h-300px w-52 card bg-base-300 rounded-box place-items-center overflow-hidden'>
                            <img
                                className='h-fit w-fit'
                                src={props.imgUrl}
                                alt='full'/>
                            {props.isLoadingFullImg ? <Spinner /> : null}
                        </div>
                        <div className='divider divider-horizontal' />
                        <div className='grid h-100px w-100px card bg-base-300 rounded-box place-items-center overflow-hidden'>
                            <img
                                className='h-fit w-fit'
                                src={props.cropUrl}
                                alt='1:1'/>
                            {props.isLoadingCropImg ? <Spinner /> : null}
                        </div>
                    </div>
                </div>
                <div className='grid h-fit space-y-6 card rounded-box place-items-center border-none'>
                    <FileInput onChange={props.onSelectImage} />
                    <button
                        disabled={!props.imgFile}
                        className='btn btn-primary focus:btn-primary text-brand-accent focus:text-brand-accent'
                        onClick={toggleCropperVisibility}>
                        crop Image
                    </button>
                </div>
            </div>

            {props.imgFile && isCropperVisible ? (
                <div className='flex items-center justify-center w-full'>
                    <Cropper
                        image={props.imgFile}
                        crop={crop}
                        zoom={zoom}
                        aspect={1 / 1}
                        onCropChange={setCrop}
                        onCropComplete={props.onCropComplete}
                        onZoomChange={setZoom}/>
                    <div className='absolute bottom-0 right-0 w-full px-6 flex justify-center'>
                        <input
                            type='range'
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby='Zoom'
                            onChange={(e) => {
                                setZoom(e.target.value);
                            }}
                            className='bg-brand-secondary w-full h-20'/>
                    </div>
                    <div className='absolute top-0 left-0 w-full py-6 flex justify-center items-center'>
                        <button
                            className={`btn ${
                                props.isLoadingCropImg ? 'loading' : ''
                            }`}
                            onClick={handleCropping}>
                            <span>Crop</span>
                        </button>
                    </div>
                </div>
            ) : null}
        </ModalWrapper>
    );
}

AddImageModal.propTypes = {
    id: PropTypes.string,
    onSelectImage: PropTypes.func,
    cropImage: PropTypes.func,
    onCropComplete: PropTypes.func,
    imgUrl: PropTypes.string,
    imgFile: PropTypes.string,
    cropUrl: PropTypes.string,
    isLoadingCropImg: PropTypes.bool,
    isLoadingFullImg: PropTypes.bool
};

export default AddImageModal;
