import React from 'react';
import PropTypes from 'prop-types';

function ModalWrapper(props) {
    return (
        <div>
            <input type='checkbox' id={props.id} className='modal-toggle' />
            <label htmlFor={props.id} className='modal cursor-pointer'>
                <label className='modal-box relative flex min-h-fit justify-center items-center' htmlFor=''>
                    {
                        props.children
                    }
                </label>
            </label>
        </div>
    );
}

ModalWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    id: PropTypes.string
};

export default ModalWrapper;
