import React from 'react';
import PropTypes from 'prop-types';
import { useZustandStore } from 'zustandStore';

function EventMediaPreview(props) {
    const { eventMedia } = useZustandStore();
    const img =
        eventMedia.img ?? props?.event?.media?.find(m => m?.type === 'IMAGE')?.url;
    const video =
        eventMedia.video ?? props?.event?.media?.find(m => m?.type === 'VIDEO')?.url;

    return (
        <div className='flex flex-row space-x-6 mx-6'>
            <div className='artboard phone-1 shadow-lg rounded-md artboard-demo relative space-y-3'>
                {img ? (
                    <img
                        className='w-full object-cover'
                        alt={`${props.event?.title}`}
                        src={img}/>
                ) : null}
                <div className='relative w-full h-1/3'>
                    <iframe
                        className='absolute left-0 top-0 h-full w-full'
                        src={video}
                        allowFullScreen={false}
                        title='Embedded youtube'/>
                </div>
            </div>
        </div>
    );
}

EventMediaPreview.propTypes = {
    event: PropTypes.object
};

export default EventMediaPreview;
