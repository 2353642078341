/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/Alert';
import { useZustandStore } from 'zustandStore';

const withAlert = (Component) => (props) => {
    const { alerts } = useZustandStore();
    const renderAlerts = React.useCallback((alert) => <Alert key={alert.id} {...alert}/>, []);

    return (
        <React.Fragment>
            <Component {...props}/>
            <div className='fixed bottom-0 left-0'>
                {
                    alerts.map(renderAlerts)
                }
            </div>
        </React.Fragment>
    );
};

withAlert.propTypes = {
    message: PropTypes.string
};

export default withAlert;
