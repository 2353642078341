import React from 'react';
import PropTypes from 'prop-types';

function ConfirmationAction(props) {
    return (
        <div className='alert shadow-lg'>
            <div>
                <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'
                    className='stroke-info flex-shrink-0 w-6 h-6'><path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'
                        d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z' /></svg>
                <span>are you sure you want to proceed.</span>
            </div>
            <div className='flex-none'>
                <button onClick={props.denyAction} className='btn btn-sm btn-ghost'>Deny</button>
                <button onClick={props.acceptAction} className='btn btn-sm btn-primary'>Accept</button>
            </div>
        </div>
    );
}

ConfirmationAction.propTypes = {
    denyAction: PropTypes.func,
    acceptAction: PropTypes.func
};

export default ConfirmationAction;
