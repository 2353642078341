import React from 'react';
import PropTypes from 'prop-types';
import RightPanel from 'components/RightPanel';
import CategoryManager from 'components/CategoryManager';
import CulturalsManager from 'components/CulturalsManager';
import AddImageModal from 'components/Modals/AddImageModal';
import { modals } from 'config/constants';
import Spinner from 'components/Spinner';
import CulturalMediaPreview from 'components/CulturalMediaPreview';

function Culturals(props) {
    return (
        <div className='flex flex-col max-h-screen w-full justify-between lg:flex-row'>
            <CulturalMediaPreview cultural={props.cultural}/>
            <div className='flex-grow max-w-3xl h-fit max-h-fit card justify-end rounded-box place-items-center overflow-hidden border-none shadow-lg'>
                {
                    props.isLoadingCultural
                        ? <div className='absolute w-full h-full z-50 opacity-75 flex justify-center items-center'>
                            <Spinner/>
                        </div>
                        : null

                }
                <div className='flex flex-col min-w-full p-3'>
                    <div className='grid h-fit w-full card rounded-box place-items-center border-none justify-start'>
                        <CategoryManager
                            data={props.data}
                            isAddingCategory={props.isAddingCategory}
                            level2data={props.level2data}
                            level3data={props.level3data}
                            level4data={props.level4data}
                            newCategoryLevel={props.newCategoryLevel}
                            onChangeCategoryInput={props.onChangeCategoryInput}
                            onSetNewCategoryLevel={props.onSetNewCategoryLevel}
                            onSubmitCategory={props.onSubmitCategory}/>
                    </div>
                    <div className='divider' />
                    <div className='grid h-fit card w-full rounded-box place-items-center border-none'>
                        <CulturalsManager
                            isUpdating={props.isUpdating}
                            submitUpdates={props.submitUpdates}
                            cultural={props.cultural}
                            isLoadingEvents={props.isLoadingEvents}
                            onSelectImage={props.onSelectImage}
                            isPublishing={props.isPublishing}
                            onCulturalChange={props.onCulturalChange}
                            submit={props.submit}
                            events={props.events}
                            onEventToggle={props.onEventToggle}/>
                    </div>
                </div>
            </div>
            <div className='divider lg:divider-horizontal' />
            <div className='grid min-w-fit w-2/6 overflow-y-scroll card rounded-box place-items-start border-none shadow-lg'>
                <RightPanel
                    isUpdatingCategory={props.isUpdatingCategory}
                    onUpdateCategory={props.onUpdateCategory}
                    isRemovingCultural={props.isRemovingCultural}
                    removeCultural={props.removeCultural}
                    isRemovingCategory={props.isRemovingCategory}
                    removeCategory={props.removeCategory}
                    data={props.data}/>
            </div>

            <AddImageModal
                isLoadingCropImg={props.isLoadingCropImg}
                isLoadingFullImg={props.isLoadingFullImg}
                cropImage={props.cropImage}
                onCropComplete={props.onCropComplete}
                imgFile={props.imgFile}
                imgUrl={props.imgUrl}
                cropUrl={props.cropUrl}
                id={modals.image}
                onSelectImage={props.onSelectImage}/>
        </div>
    );
}

Culturals.propTypes = {
    data: PropTypes.array,
    removeCategory: PropTypes.func,
    isRemovingCategory: PropTypes.bool,
    level2data: PropTypes.array,
    level3data: PropTypes.array,
    level4data: PropTypes.array,
    onSubmitCategory: PropTypes.func,
    isAddingCategory: PropTypes.bool,
    onChangeCategoryInput: PropTypes.func,
    newCategoryLevel: PropTypes.number,
    onSetNewCategoryLevel: PropTypes.func,
    events: PropTypes.array,
    onEventToggle: PropTypes.func,
    submit: PropTypes.func,
    onCulturalChange: PropTypes.func,
    onSelectImage: PropTypes.func,
    isPublishing: PropTypes.bool,
    imgFile: PropTypes.string,
    imgUrl: PropTypes.string,
    cropUrl: PropTypes.string,
    cropImage: PropTypes.func,
    onCropComplete: PropTypes.func,
    submitUpdates: PropTypes.func,
    isLoadingCropImg: PropTypes.bool,
    isLoadingFullImg: PropTypes.bool,
    isLoadingEvents: PropTypes.bool,
    isLoadingCultural: PropTypes.bool,
    isUpdating: PropTypes.bool,
    cultural: PropTypes.object,
    removeCultural: PropTypes.func,
    isRemovingCultural: PropTypes.bool,
    onUpdateCategory: PropTypes.func,
    isUpdatingCategory: PropTypes.bool
};

export default Culturals;
