import storage from 'react-secure-storage';

export const storageKeys = {
    authToken: 'authToken'
};

/**
 * Sets local storage data
 * @param {String} key
 * @param {String} value
 * @returns {void}
 */
export const setLocalStorage = (key, value) => {
    storage.setItem(key, value);
};

/**
 * Gets local storage data
 * @param {String} key
 * @param {String} value
 * @returns {String}
 */
export const getLocalStorage = (key) => {
    return storage.getItem(key);
};

/**
 * Remove local storage key
 * @param {String} key
 * @returns {void}
 */
export const removeLocalStorage = (key) => {
    storage.removeItem(key);
};

/**
 * clear all local storage key
 * @returns {void}
 */
export const clearAllLocalStorage = () => {
    storage.clear();
};
