import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useZustandAuth } from 'zustandStore';
import Main from 'containers/Main';
import { routes, STORAGEKEY_AUTH_TOKEN } from 'config/constants';
import { getLocalStorage } from 'utils/storage';

const ProtectedRoute = props => {
    const { isAuthed } = useZustandAuth();
    const token = getLocalStorage(STORAGEKEY_AUTH_TOKEN);

    if (!isAuthed && !token) {
        return <Navigate to={routes.login}/>;
    }

    return <Main>
        {
            props.children
        }
    </Main>;
};

ProtectedRoute.propTypes = {
    children: PropTypes.element
};

export default ProtectedRoute;
