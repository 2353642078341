import React from 'react';
import {
    useMainCategories,
    useOtherCategories,
    useRemoveCategory,
    useUpdateCategory
} from 'hooks/categories';
import withAlert from 'containers/HOCs/withAlert';
import { useZustandStore } from 'zustandStore';
import Events from 'screens/Events';
import { useDeleteEvent, useEventCulturals } from 'hooks/events';
import useNewEvent from 'hooks/useNewEvent';

function EventsContainer(props) {
    const { activeEvent, activeTab, classification } = useZustandStore();
    const categories = Object.values(classification[activeTab] ?? {})
        ?.map((category) => category?.id)
        ?.join(',');

    const { event, isFetchingCategoryCulturals, isLoadingEvent } =
        useEventCulturals(
            activeEvent,
            categories,
            !!activeEvent
        );

    const {
        handleOnEventUpdate,
        onCulturalToggle,
        onEventChange,
        onSubmitEvent,
        isCreatingEvent,
        isUpdatingEvent
    } = useNewEvent(event);

    const { isDeletingEvent, onDeleteEvent } = useDeleteEvent();

    const { data, isLoading } = useMainCategories();

    const { data: level2data } = useOtherCategories(
        classification[activeTab]?.['1']?.code,
        2,
        !!classification[activeTab]?.['1']?.code
    );
    const { data: level3data } = useOtherCategories(
        classification[activeTab]?.['2']?.code,
        3,
        !!classification[activeTab]?.['2']?.code
    );
    const { data: level4data } = useOtherCategories(
        classification[activeTab]?.['3']?.code,
        4,
        !!classification[activeTab]?.['3']?.code
    );

    const { isLoading: isRemovingCategory, onRemoveCategory } =
        useRemoveCategory();

    const { isUpdatingCategory, onUpdateCategory } = useUpdateCategory();

    const handleRemoveCategory = (category) => {
        onRemoveCategory(category);
    };

    if (isLoading) {
        return <div>...loading</div>;
    }

    return (
        <Events
            data={data}
            level2data={level2data}
            level3data={level3data}
            level4data={level4data}
            removeCategory={handleRemoveCategory}
            isRemovingCategory={isRemovingCategory}
            event={event}
            onEventChange={onEventChange}
            isLoadingEvent={isLoadingEvent}
            submitUpdates={handleOnEventUpdate}
            publish={onSubmitEvent}
            removeEvent={onDeleteEvent}
            isRemovingEvent={isDeletingEvent}
            isPublishing={isCreatingEvent}
            isUpdating={isUpdatingEvent}
            isLoadingCulturals={isFetchingCategoryCulturals}
            isUpdatingCategory={isUpdatingCategory}
            onUpdateCategory={onUpdateCategory}
            onCulturalToggle={onCulturalToggle}/>
    );
}

EventsContainer.propTypes = {};

export default withAlert(EventsContainer);
