export async function getCroppedImg(
    imageSrc,
    pixelCrop,
    flip = { horizontal: false, vertical: false }
) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        return null;
    }

    // calculate bounding box of the rotated image

    // set canvas size to match the bounding box
    canvas.width = image.width;
    canvas.height = image.height;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(image.width / 2, image.height / 2);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values

    if (pixelCrop) {
        const data = ctx.getImageData(
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height
        );

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        // paste generated rotate image at the top left corner
        ctx.putImageData(data, 0, 0);
    }

    return new Promise((resolve, reject) => {
        function readFile(file) {
            return new Promise((resolve) => {
                const reader = new FileReader();

                reader.addEventListener(
                    'load',
                    () => resolve(reader.result),
                    false
                );
                reader.readAsDataURL(file);
            });
        }

        canvas.toBlob(async (file) => {
            const base64String = await readFile(file);

            resolve(base64String);
        }, 'image/jpeg');
    });
}

export const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();

        // image.crossOrigin = 'Anonymous';

        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.src = url;
    });

export const getTranslatedField =
    (translations) =>
        (...params) => {
            const [lang, field] = params;

            return translations?.find(
                (tr) => tr?.lang === lang && tr.translatable_field === field
            )?.content;
        };

export const getFullSizeFormat = (media) =>
    media?.find((m) => m.metadata?.size === 'full' && m.type === 'IMAGE')?.url;
export const getTileFormat = (media) =>
    media?.find((m) => {
        const metadata =
            typeof m.metadata === 'string'
                ? JSON.parse(m.metadata)
                : m.metadata;

        return metadata.size === '1x1';
    })?.url;
