import { axiosInstance, endPoints } from 'api';
import { useMutation } from '@tanstack/react-query';
import { useZustandStore } from 'zustandStore';

async function uploadImage(file) {
    const result = await axiosInstance.post(endPoints.upload.image, { file });

    if (!result.status) {
        const error = new Error();

        error.message = 'Something went wrong! please try again, or choose another file!';
        error.result = result?.response;
        error.status = 413;

        throw error;
    }

    return result.data;
}

export const useUploadImage = () => {
    const { createAlert } = useZustandStore();
    const { mutate: onUploadImage, isLoading } = useMutation({
        mutationKey: [endPoints.upload.image],
        mutationFn: uploadImage,
        onError: (err) => {
            createAlert({ type: 'alert-error', message: err?.message });
        }
    });

    return {
        onUploadImage,
        isLoading
    };
};
