import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'api';
import AppContainer from 'containers/App';
import 'styles/index.css';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
    <QueryClientProvider client={queryClient}>
        <AppContainer/>
    </QueryClientProvider>
);
