import { create } from 'zustand';

export const FONT_SIZES = [
    14,
    15,
    16,
    18,
    20,
    22,
    24,
    28,
    32
];

export const POSITIONS = [
    'Left',
    'Right'
];

export const LINK_SCREENS = [
    undefined,
    'Profile',
    'Settings',
    'Privacy',
    'About',
    'Categories',
    'Contacts',
    'Share'
];

const initialState = {
    backgroundColor: '#F41268',
    textContent: {
        en: undefined,
        fr: undefined
    },
    linkTitle: {
        en: undefined,
        fr: undefined
    },
    fontSize: FONT_SIZES[0],
    textPosition: POSITIONS[0],
    letterSpacing: 0,
    illustartionUrl: undefined,
    illustrationPosition: POSITIONS[1],
    linkScreen: undefined,
    linkPosition: POSITIONS[0],
    updateMode: false,
    selectedCard: undefined

};

export const useIllustrationStore = create((set) => ({
    ...initialState,
    setBackgroundColor: backgroundColor => set(() => ({ backgroundColor })),
    setTextContent: textContent => set((state) => ({ textContent: { ...state.textContent, [textContent.lang]: textContent.value } })),
    setLinkTitle: title => set((state) => ({ linkTitle: { ...state.linkTitle, [title.lang]: title.value } })),
    setFontSize: fontSize => set(() => ({ fontSize })),
    setTextPosition: position => set(() => ({ textPosition: position, illustrationPosition: POSITIONS.find(_p => _p !== position) })),
    setLetterSpacing: letterSpacing => set(() => ({ letterSpacing })),
    setIllustartionUrl: illustartionUrl => set(() => ({ illustartionUrl })),
    setIllustrationPosition: position => set(() => ({ illustrationPosition: position, textPosition: POSITIONS.find(_p => _p !== position) })),
    setLinkScreen: linkScreen => set(() => ({ linkScreen })),
    setLinkPosition: linkPosition => set(() => ({ linkPosition })),
    turnOnUpdateMode: () => set(() => ({ updateMode: true })),
    turnOffUpdateMode: () => set(() => ({ ...initialState })),
    selectCard: (selectedCard) => set(() => ({ selectedCard }))
}));
