import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from '../ModalWrapper';
import FileInput from 'components/FileInput';
import Cropper from 'react-easy-crop';
import Spinner from 'components/Spinner';
import useImagePicker from 'hooks/useImagePicker';
import { useZustandStore } from 'zustandStore';

function AddHighlightCover(props) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [isCropperVisible, setCropperVisibility] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const { setHighlightCover } = useZustandStore();

    const {
        cropImage,
        cropUrl,
        imgFile,
        isLoadingCropImg,
        pickImage
    } = useImagePicker();

    useEffect(() => {
        setCropperVisibility(!!imgFile);
    }, [imgFile]);

    const onSelectImage = (evt) => {
        if (evt?.target?.files) {
            pickImage(evt?.target?.files[0]);
        }
    };

    const toggleCropperVisibility = () => setCropperVisibility((prev) => !prev);

    const onImageCrop = () => {
        cropImage(imgFile, croppedAreaPixels, toggleCropperVisibility);
    };

    const onCropComplete = (_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    useEffect(() => {
        if (cropUrl) {
            setHighlightCover(cropUrl);
        }
    }, [cropUrl, setHighlightCover]);

    return (
        <ModalWrapper id={props.id}>
            <div className='space-y-6'>
                <div className='flex w-full h-fit justify-center items-center'>
                    <div className='grid h-[320px] w-[240px] card bg-base-300 rounded-box place-items-center overflow-hidden'>
                        <img
                            className='h-fit w-fit cover'
                            src={cropUrl}
                            alt='1:1.3'/>
                        {isLoadingCropImg ? <Spinner /> : null}
                    </div>
                </div>
                <div className='grid h-fit space-y-6 card rounded-box place-items-center border-none'>
                    <FileInput onChange={onSelectImage} />
                    <button
                        disabled={!imgFile}
                        className='btn btn-primary focus:btn-primary text-brand-accent focus:text-brand-accent'
                        onClick={toggleCropperVisibility}>
                        crop Image
                    </button>
                </div>
            </div>

            {imgFile && isCropperVisible ? (
                <div className='flex items-center justify-center w-full'>
                    <Cropper
                        image={imgFile}
                        crop={crop}
                        zoom={zoom}
                        aspect={1 / 1.3}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}/>
                    <div className='absolute bottom-0 right-0 w-full px-6 flex justify-center'>
                        <input
                            type='range'
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby='Zoom'
                            onChange={(e) => {
                                setZoom(e.target.value);
                            }}
                            className='bg-brand-secondary w-full h-20'/>
                    </div>
                    <div className='absolute top-0 left-0 w-full py-6 flex justify-center items-center'>
                        <button
                            className={`btn ${
                                isLoadingCropImg ? 'loading' : ''
                            }`}
                            onClick={onImageCrop}>
                            <span>Crop</span>
                        </button>
                    </div>
                </div>
            ) : null}
        </ModalWrapper>
    );
}

AddHighlightCover.propTypes = {
    id: PropTypes.string
    // onSelectImage: PropTypes.func,
    // cropImage: PropTypes.func,
    // onCropComplete: PropTypes.func,
    // imgUrl: PropTypes.string,
    // imgFile: PropTypes.string,
    // cropUrl: PropTypes.string,
    // isLoadingCropImg: PropTypes.bool,
    // isLoadingFullImg: PropTypes.bool
};

export default AddHighlightCover;
